import React, { useState, useEffect, useRef } from 'react';
import { Card, Container, Row, Col, Stack, Nav, Tab, ListGroup, Button, } from 'react-bootstrap';
import NewnavHome from '../Components/NewnavHome'
import Newnav from '../Components/Newnav';
import Rice from '../Images/Rice.png';
import Vegitable from '../Images/vegitable3.jpg'
import { Link } from 'react-router-dom';
import Activity from '../Components/Activity';
import Newfooter from '../Components/Newfooter';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Dropdown from 'react-bootstrap/Dropdown';
import Placeholder from 'react-bootstrap/Placeholder';
import Download from '../Images/download.png'
import Pagination from 'react-bootstrap/Pagination';
import Empty from '../Images/empty.png'
import WishlistIcon from '../Images/wishlist.png'


export const Categories = () => {
    const [bearer, setBearer] = useState('');
    const [selectedSortBy, setSelectedSortBy] = useState('Sort by');
    const [selectedCategory, setSelectedCategory] = useState(null); 
    const [selectedBrand, setSelectedBrand] = useState(null); 
    const [displayedProducts, setDisplayedProducts] = useState([]);
    const [authenticated, setAuthenticated] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [searchBrands, setSearchBrands] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    

  



    const handleSearchInputChange = (event) => {
        const inputValue = event.target.value;
        setSearchInput(inputValue);
    
        if (inputValue) {
          // Filter products when searching
          const filteredProducts = originalProducts.filter((item) =>
            item.name.toLowerCase().includes(inputValue.toLowerCase())
          );
          setSearching(true);
          setDisplayedProducts(filteredProducts);
        } else {
          resetDisplayedProducts();
        }
      };

    useEffect(() => {
        // Scroll to the top of the page when the currentPage changes
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [currentPage]);


    const readData = async () => {
        try {
            const value2 = await AsyncStorage.getItem('userToken');

            if (value2 !== null) {
                // console.log(value2);
                setBearer(value2);
                setAuthenticated(true);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };
    useEffect(() => {
        readData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    const [items, setItems] = useState([]);
    const [brands, setBrands] = useState([]);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const itemsPerPage = 9;
    const [addToCartPressed, setAddToCartPressed] = useState(false);
    const [loadingItemId, setLoadingItemId] = useState(false);
    const [showItemAdded, setShowItemAdded] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [isCategoriesListVisible, setIsCategoriesListVisible] = useState(true);
    const [isBrandListVisible, setIsBrandListVisible] = useState(true);
    const [addToWishlistPressed, setAddToWishlistPressed] = useState(false);
    const [loadingWishlistId, setLoadingWishlistId] = useState(false);
    const [wishlistStatus, setWishlistStatus] = useState({});
    const [wishlistButtonText, setWishlistButtonText] = useState({});
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [loadingWishlist, setLoadingWishlist] = useState(false);
    const [ratings, setRatings] = useState([]);

    const handleMediaQuery = () => {
        const mediaQuery = window.matchMedia('(max-width: 800px)'); // Adjust the media query as needed
        if(mediaQuery.matches){
            setIsCategoriesListVisible(false);
            setIsBrandListVisible(false);
        }
        // setIsMediumScreen(mediaQuery.matches);
      };
    const [searching, setSearching] = useState(false);
    const [originalProducts, setOriginalProducts] = useState([]);

    const resetDisplayedProducts = () => {
        setSearching(false); // Reset searching flag
        setDisplayedProducts(originalProducts);
        setSearchInput(''); // Clear the search input
      };

      const saveItemIdToStorage = async (itemId) => {
        try {
          await AsyncStorage.setItem('itemId', itemId);
        } catch (error) {
          console.error('Error saving itemId to AsyncStorage:', error);
        }
      };

      const handleItemClick = (item) => {
        saveItemIdToStorage(item.id);
    }

    const handleAddToCart = async (productId) => {
        setLoadingItemId(productId);

        try {

            const response = await axios.post(
                'https://gtc.ogunstate.gov.ng/api/add-to-cart',
                {
                    product_id: productId,
                    quantity: 1,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`
                    }
                }
            );

            setAddToCartPressed(true);
            setButtonDisabled(true);

            console.log('Success:', response.data.message);

            setShowItemAdded(true);
            setTimeout(() => {
                setShowItemAdded(false);
            }, 2000);
        } catch (error) {
            // Handle error response
            const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
            console.log('Error:', errorMessage);

            if (error.response.status === 401) {

                navigate('/sign_in', { state: { from: location.pathname } });
            }
        } finally {
            setLoadingItemId(false);
        }
    };

    const navigate = useNavigate();





    const totalItems = displayedProducts.length;
    const pageCount = Math.ceil(totalItems / itemsPerPage);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < pageCount) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleLastPage = () => {
        setCurrentPage(pageCount);

    };


    const filteredAndPaginatedItems = displayedProducts
        .filter((item) => item.name.toLowerCase().includes(searchInput.toLowerCase()))
        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);


    const fetchAllItems = async () => {
        setLoading(true);

        try {
            const response = await axios.get('https://gtc.ogunstate.gov.ng/api/get-category-products');
            const responseData = response?.data?.data;
            const products = responseData.map(item => item.products).flat();
            const productsss = products.map(item => item?.ratings).flat();
            setRatings(productsss);
            setOriginalProducts(responseData.map(item => item.products).flat());


            if (responseData && responseData.length > 0) {
                setItems(responseData);
                
                setSelectedCategory(responseData[0].id); // Set the first category as the default
                setDisplayedProducts(responseData[0].products);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchAllBrands = async () => {
        setLoading(true);

        try {
            const response = await axios.get('https://gtc.ogunstate.gov.ng/api/get-all-brands');
            const responseData = response?.data?.data;
           

            if (responseData && responseData.length > 0) {
                setBrands(responseData);
                setSelectedBrand(responseData[0].id);  
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        fetchAllItems();
        fetchAllBrands();
        handleMediaQuery()

    }, []);


   

    const handleSortSelection = (option) => {
        setSelectedSortBy(option);
        const sortedProducts = sortProducts(originalProducts, option, selectedBrand);
        setDisplayedProducts(sortedProducts);
    };
    const placeholderCount = 6;

    const handleCategoryClick = (categoryId) => {
        if (categoryId !== selectedCategory) {
            setSelectedCategory(categoryId);
            setSelectedBrand(null);
            setCurrentPage(1); // Reset pagination to the first page
            const selectedCategoryData = items.find((item) => item.id === categoryId);
            if (selectedCategoryData) {
                setDisplayedProducts(selectedCategoryData.products);
            }
        }
    };

    

    const handleBrandClick = (brandId) => {
        if (brandId !== selectedBrand) {
            setSelectedBrand(brandId);
            const selectedBrandData = brands.find((brand) => brand.id === brandId);
            if (selectedBrandData) {
                // Filter products based on the selected brand
                const filteredProducts = originalProducts.filter((product) =>
                    product.brands.id === selectedBrandData.id
                );
                // Apply sorting if needed
                const sortedProducts = sortProducts(filteredProducts, selectedSortBy, selectedBrandData.id);
                setDisplayedProducts(sortedProducts);
            }
        }
    };

    const sortAlphabetically = (products) => {
        return [...products].sort((a, b) =>
          a.name.localeCompare(b.name, undefined, { sensitivity: 'base' })
        );
      };
      
      const sortByDiscountPrice = (products) => {
        return [...products].sort((a, b) => b.discount_price - a.discount_price);
      };
      
      const sortByCreationDate = (products) => {
        return [...products].sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
      };

      const sortByBrands = (products, selectedBrand) => {
        if (selectedBrand) {
            return products.filter(product => product.brands.id === selectedBrand);
        }
        return products;
    };

      const sortProducts = (products, sortBy, selectedBrand) => {
        let sortedProducts = [...products];
      
        switch (sortBy) {
          case 'Sort by Newest Arrivals':
            sortedProducts = sortByCreationDate(sortedProducts);
            break;
          case 'Sort by price: high to low':
            sortedProducts = sortByDiscountPrice(sortedProducts);
            break;
          case 'Sort by price: low to high':
            sortedProducts = sortByDiscountPrice(sortedProducts).reverse();
            break;
       case 'Sort by Brands':
            sortedProducts = sortByBrands(sortedProducts, selectedBrand);
            break;
        default:
            break;
        }
      
        return sortedProducts;
      };
      
      
      const handleWishlist = async (productId) => {
        setLoadingWishlist(true);
        setSelectedItemId(productId);
      
        try {
          const response = await axios.post(
            'https://gtc.ogunstate.gov.ng/api/add-to-wish-list',
            {
              product_id: productId,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${bearer}`,
              },
            }
          );
      
          // Set the loading state to false and wishlist status to true only for the selected item
          setLoadingWishlist(false);
          setWishlistStatus((prevStatus) => ({
            ...prevStatus,
            [productId]: true,
          }));
      
          // Display success message for 3 seconds
          setTimeout(() => {
            setWishlistStatus((prevStatus) => ({
              ...prevStatus,
              [productId]: false,
            }));
          }, 3000);
        } catch (error) {
          const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
          if (error.response && error.response.status === 401) {
            navigate('/sign_in');
          }
          console.log('Error:', errorMessage);
          setLoadingWishlist(false);
        }
      };
      

      const calculateAverageRating = (productRatings) => {
        if (!productRatings || productRatings.length === 0) {
          return 0; // Default to 0 if no ratings
        }
      
        const totalRating = productRatings.reduce((acc, rating) => acc + parseFloat(rating.rating), 0);
        const averageRating = totalRating / productRatings.length;
        // console.log(averageRating, "addkdk");
        return averageRating;
      };


    return (
        <div>
            {authenticated ?
                <NewnavHome />
                :
                <Newnav />
            }
            <div className='body' style={{ backgroundColor: 'white', }}>
                <div >
                    <div className={`item-added-banner ${showItemAdded ? '' : 'hidden'}`}>
                        Item added
                    </div>
                </div>
                <div className='main' style={{ backgroundColor: 'white', }}>
                    <Container fluid className='mainContainer'>
                        <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                            <Row >
                                <Col lg={3} className='categries-menu'>
                                    <div className='sideNav-search'>
                                        <input
                                            type='search'
                                            placeholder='Search items..'
                                            value={searchInput} // Step 3: Display the search input value
                                            onChange={handleSearchInputChange} // Step 4: Handle input changes
                                        />
                                        {/* <button>
                                            <i className='bx bx-search' style={{ fontSize: '20px', color: '#667185' }}></i>
                                        </button> */}
                                    </div>
                                    <button className="navbar-toggler categries-mobile-menu " type="button" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation"
                                    onClick={()=> setIsCategoriesListVisible(!isCategoriesListVisible)}>
                                        <i className="fas fa-bars"></i>
                                    </button>
                                    {isCategoriesListVisible &&
                                    <ListGroup className='sideNaves hide-on-mobile'>
                                        {loading ? (
                                            Array.from({ length: placeholderCount }, (_, index) => (
                                                <ListGroup.Item key={index} action disabled>
                                                    <Placeholder as="p" animation="glow">
                                                        <Placeholder xs={6} />
                                                    </Placeholder>
                                                </ListGroup.Item>
                                            ))
                                        ) : (
                                            items.map((item, index) => (
                                                <ListGroup.Item
                                                    key={index}
                                                    action
                                                    className={`list-group-items ${selectedCategory === item.id ? 'selected-category' : ''}`}
                                                    onClick={() => handleCategoryClick(item.id)}
                                                >
                                                    {item.name
                                                        .toLowerCase()
                                                        .split(' ')
                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                        .join(' ')}
                                                </ListGroup.Item>
                                            ))
                                        )}
                                    </ListGroup>
                                    }
                                    <div style={{marginTop: 20}} />
                                    <hr />
                                    {/* <div className='sideNav-search'>
                                        <input
                                            type='search'
                                            placeholder='Search brands..'
                                            value={searchBrands} 
                                            onChange={handleSearchInputChange} 
                                        />
                                        
                                    </div> */}
                                    <div style={{marginTop: 20}} />
                                    <h5>Brands</h5>
<button
  className="navbar-toggler categries-mobile-menu"
  type="button"
  aria-controls="navbarNav"
  aria-expanded="false"
  aria-label="Toggle navigation"
  onClick={() => setIsBrandListVisible((prevState) => !prevState)}
>
  <i className="fas fa-bars"></i>
</button>
{isBrandListVisible && (
  <ListGroup className="sideNaves hide-on-mobile">
    {loading ? (
      Array.from({ length: placeholderCount }, (_, index) => (
        <ListGroup.Item key={index} action disabled>
          <Placeholder as="p" animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
        </ListGroup.Item>
      ))
    ) : (
      brands.map((item, index) => (
        <ListGroup.Item
          key={index}
          action
          className={`list-group-items ${selectedBrand === item.id ? 'selected-category' : ''}`}
          onClick={() => handleBrandClick(item.id)}
        >
          {item.name
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}
        </ListGroup.Item>
      ))
    )}
  </ListGroup>
)}

                                </Col>
                                <Col lg={9} >
                                    <div className="filter-bar d-flex flex-wrap align-items-center justify-content-between">
                                        <p className="fs-15 my-2">Showing <span className="text-black">
                                            {currentPage === pageCount
                                                ? `${(currentPage - 1) * itemsPerPage + 1} - ${totalItems} of ${totalItems} items`
                                                : `${(currentPage - 1) * itemsPerPage + 1} - ${currentPage * itemsPerPage} of ${totalItems} items`
                                            }
                                        </span></p>

                                        <Dropdown className="d-inline mx-2">
                                        <Dropdown.Toggle id="dropdown-autoclose-true">
  {selectedSortBy}
</Dropdown.Toggle>

<Dropdown.Menu>
  <Dropdown.Item onClick={() => handleSortSelection('Sort by Newest Arrivals')}>
    Newest Arrivals
  </Dropdown.Item>
  <Dropdown.Item onClick={() => handleSortSelection('Sort by price: high to low')}>
    Price: high to low
  </Dropdown.Item>
  <Dropdown.Item onClick={() => handleSortSelection('Sort by price: low to high')}>
    Price: low to high
  </Dropdown.Item>
</Dropdown.Menu>

                                        </Dropdown>


                                    </div>
                                    <Row mt={4}>
                                        {loading ? (
                                            Array.from({ length: placeholderCount }, (_, index) => (
                                                <Col lg={4} className='lg4' key={index}>
                                                    <Card className='text-center hover-y overflow-hidden card'>
                                                        <div>
                                                            <div id='imgs' style={{ cursor: 'default' }}>
                                                                <Card.Img variant="top" src={Download} alt="loading..." />
                                                            </div>
                                                            <Card.Body>
                                                                <Placeholder as={Card.Title} animation="glow">
                                                                    <Placeholder xs={6} />
                                                                </Placeholder>
                                                            </Card.Body>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            ))
                                        ) : (searching || filteredAndPaginatedItems.length > 0) ? (
                                            filteredAndPaginatedItems.map((item, index) => (
                                                <Col key={index} lg={4} className='lg4'>
                                                    <Card className='text-center hover-y overflow-hidden card'>
                                                        <div>
                                                        <Link to="/product-details" onClick={() => handleItemClick(item)}>
                                                            <div id='imgs' style={{ cursor: 'pointer' , position: 'relative'}}>
                                                            
                                                                <Card.Img
                                                                    variant='top'
                                                                    src={item.image}
                                                                    alt={item.name.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                                                    style={{width: "267.98px", height: "186.13px"}}
                                                                />
                                                                    {item.discount_type && (
                                                                        <div
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: '10px',
                                                                                left: '10px',
                                                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                                                color: '#fff',
                                                                                padding: '5px',
                                                                                borderRadius: '5px',
                                                                            }}
                                                                        >
                                                                            {item.discount_type === '1' && item.discount_percent && (
                                                                                <span style={{ color: '#fff' }}>
                                                                                    -{parseFloat(item.discount_percent).toString().replace(/\.00$/, '')}%
                                                                                </span>
                                                                            )}

                                                                            {item.discount_type === '2' && item.flat_discount && (
                                                                                <span style={{ color: '#fff' }}>-₦ {item.flat_discount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                            </Link>
                                                            <Card.Body id='cardBody'>
                                                                <Card.Title>
                                                                    <Link id='productName' to={'#'}>
                                                                        {item.name.toLowerCase().split(' ').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                                                                    </Link>
                                                                </Card.Title>
                                                                <Card.Text id='price'>
                                                                ₦ {Number(item.discount_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                {item.discount_price !== item.price && (
    <span className="mdr categories-text" >
      <del>₦ {Number(item.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</del>
    </span>
  )}
                                                                </Card.Text>
                                                                <Card.Text id='rates'>
                                                                {calculateAverageRating(item.ratings) > 0 ? (
    Array.from({ length: 5 }, (_, index) => (
      <span key={index}>
        {index < Math.floor(calculateAverageRating(item.ratings)) ? (
          <i className="fas fa-star" style={{ color: 'gold' }}></i>
        ) : index - Math.floor(calculateAverageRating(item.ratings)) === 0.5 ? (
          <i className="fas fa-star-half-alt" style={{ color: 'gold' }}></i>
        ) : (
          <i className="far fa-star" style={{ color: 'gold' }}></i>
        )}
      </span>
    ))
  ) : null}
                                                                    {/* <span> {item.ratings.length} review</span> */}
                                                                    
                                                                </Card.Text>



                                                                {loadingItemId === item.id ? (
                                                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Activity />
                                                                    </div>
                                                                ) : (
                                                                    <Button id='add-to-cart-btn' onClick={() => handleAddToCart(item.id)} style={{ cursor: "pointer" }} variant='success'>
                                                                        <i className='bx bx-cart'></i>Add To Cart
                                                                    </Button>
                                                                )}
                                                                <Button
  id="wishlist-btn"
  onClick={() => handleWishlist(item.id)}
  style={{
    cursor: 'pointer',
    width: '100%',
    color: '#B92347',
    fontWeight: 700,
  }}
  variant="danger"
  disabled={loadingWishlist}
>
  {loadingWishlist[item.id]
    ? 'Adding Item to Wishlist...'
    : wishlistStatus[item.id]
      ? 'Item Added to Wishlist!'
      : 'Add to Wishlist'}
</Button>
                                                            </Card.Body>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            ))
                                        ) : (
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}>
                                                <img src={Empty} alt='Empty' />
                                                <p style={{ marginTop: "14px" }}>No products available in this category</p>
                                            </div>
                                        )}

                                    </Row>
                                </Col>
                            </Row>
                        </Tab.Container>



                    </Container>
                </div>

                <Pagination size='lg'>
                    <Pagination.First onClick={handleFirstPage} />
                    <Pagination.Prev onClick={handlePrevPage} disabled={currentPage === 1} />
                    {Array.from({ length: pageCount }, (_, index) => (

                        <Pagination.Item
                            key={index}
                            active={currentPage === index + 1}
                            onClick={() => handlePageClick(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={handleNextPage} disabled={currentPage === pageCount} />
                    <Pagination.Last onClick={handleLastPage} />
                </Pagination>
            </div>

            <Newfooter />
        </div>
    )
}