
import './AppResponsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, HashRouter } from 'react-router-dom';
import {Onboarding} from './Webpages/Onboarding'
import { Categories } from './Webpages/Categories';
import { ProductDetails,} from './Webpages/Productdetails';
import CheckoutPage from './Webpages/CheckoutPage';
// import User from './Webpages/User';
import React, {useState, useEffect} from 'react';
import SignUp from './Webpages/SignUp';
import { PassWordSuccessfull } from './Webpages/PassWordSuccessfull';
import { LoginSuccessful } from './Webpages/LoginSuccessful';
// import { HomePage } from './Webpages/HomePage';
import Signin from './Webpages/Signin';
import { Vegitables } from './Webpages/Vegitables';
import { SelectedCategory } from './Webpages/SelectedCategory';
import { HomeAndFurniture } from './Webpages/HomeAndFurniture';
import { MyProfile } from './Components/MyProfile';
import ShoppingCarts from './Webpages/ShoppingCarts';
import { ChangePassWord } from './Webpages/ChangePassWord';
import { AddressBook } from './Webpages/AddressBook';
import { AboutUs } from './Webpages/AboutUs';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Wishlist } from './Webpages/Wishlist';
import { ContactUs } from './Webpages/ContactUs';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FAQ } from './Webpages/FAQ'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { SelectedProduct } from './Webpages/SelectedProduct';
import 'font-awesome/css/font-awesome.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
// import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import './index.css'; 
import ForgotPassword from './Webpages/ForgotPassword';
import Setpassword from './Webpages/Setpassword';
import { useLocation } from 'react-router-dom';
import { CartProvider } from './Components/CartContext';
// import { SelectedCategoryProduct } from './Webpages/SelectedCategoryProduct';
<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
  integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM"
  crossorigin="anonymous"
/>



function App() {
  const [userIsInactive, setUserIsInactive] = useState(false);
  const inactivityThreshold = 600000; 
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Scroll to the top of the page when the route changes
    window.scrollTo(0, 0);
  }, [location]);

  
  let inactivityTimer;
  
  const resetInactivityTimer = () => {
    if (inactivityTimer) {
      clearTimeout(inactivityTimer);
    }
  
    inactivityTimer = setTimeout(async () => {
    
      setUserIsInactive(true);
      await AsyncStorage.clear();
      navigate('/sign_in');
      
    }, inactivityThreshold);
  };
  
  const handleUserActivity = () => {
    resetInactivityTimer();
  };
  
  useEffect(() => {
    resetInactivityTimer();
  
    const activityEvents = ['mousemove', 'keydown', 'mousedown', 'touchstart'];
    activityEvents.forEach((event) => {
      document.addEventListener(event, handleUserActivity);
    });
  
    return () => {
      activityEvents.forEach((event) => {
        document.removeEventListener(event, handleUserActivity);
      });
  
      if (inactivityTimer) {
        clearTimeout(inactivityTimer);
      }
    };
  }, []);
  


  return (
    <>
 <CartProvider>
      <Routes>
        <Route path='/' element={<Onboarding />} />
        <Route path='/categories' element={<Categories />} />
        <Route path="/product" element={<ProductDetails />} />
        <Route path='/checkoutPage' element={<CheckoutPage />} />
        <Route path='/sign_up' element={<SignUp />} />
        <Route path='/sign_in' element={<Signin />} />
        <Route path='/onboarding' element={<Onboarding />} />
        <Route path='/categories' element={<Categories />} />
       
        <Route path='/checkoutPage' element={<CheckoutPage />} />
        <Route path='/signUp' element={<SignUp />} />
        <Route path='/signin' element={<Signin />} />
        {/* <Route path='/user' element={<User />} /> */}
        <Route path='/passWord_successfull' element={<PassWordSuccessfull />} />
        <Route path='/login_successful' element={<LoginSuccessful />} />
        {/* <Route path='/homepage' element={<HomePage />} /> */}
        <Route path='/vegitables' element={<Vegitables/>} />
        <Route path="/selected-category/:categoryName" element={<SelectedCategory />} />
        <Route path='/home_and_furniture' element={<HomeAndFurniture/>} />
        <Route path='/my_profile' element={<MyProfile/>}/>
        <Route path='/shopping_carts' element={<ShoppingCarts/>}/>
        <Route path='/change_passWord' element={<ChangePassWord/>}/>
        <Route path='/address_book' element={<AddressBook/>}/>
        <Route path='/about_us' element={<AboutUs/>}/>
        <Route path='/contact_us' element={<ContactUs/>}/>
        <Route path='/wish_list' element={<Wishlist />}/>
        <Route path='/faq' element={<FAQ/>}/>
        <Route path="/product-details" element={<SelectedProduct />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/set_password" element={<Setpassword />} />
        
        {/* <Route path="/selected-category-product/:categoryName" element={<SelectedCategoryProduct />} /> */}
        {/* <Route path='/ProductSidebar' element={<ProductSidebar/>}/> */}
        
      </Routes>
      </CartProvider>
    </>
  );
}
export default App;
