import React, {useEffect, useState} from 'react';
import Newfooter from '../Components/Newfooter';
// import ContactImg from '../Images/ContactImg.png';
import Contactus from '../Images/Contactus.jpg';
import { Link } from 'react-router-dom';
import NewnavHome from '../Components/NewnavHome';
import Newnav from '../Components/Newnav';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const ContactUs = () => {

    const [bearer, setBearer] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    const readData = async () => {
      try {
          const value2 = await AsyncStorage.getItem('userToken');

          if (value2 !== null) {
              // console.log(value2);
              setBearer(value2);
              setAuthenticated(true);
          }
      } catch (e) {
          alert('Failed to fetch the input from storage');
      }
  };
  useEffect(() => {
      readData();
  }, []);


  return (
    <div>
        <div>
        {authenticated ? 
           <NewnavHome /> 
            :
            <Newnav />
}
        </div>
    {/* <div style={{marginBottom:'30px',}}></div> */}
    <div className='body'>
        <div className='main'>
            <div className='container-fluid'>
                <div className='contact-details sections'>
                <form> 
                    <div className='get-Intorch'>
                        <h2>Get in touch</h2>
                        <p>Our friendly team would love to hear from you.</p>
                    </div>
                    {/* <div > */}
                    <div id='contanctUs'>
                        <div className='div'>
                            <div className="form-group">
                                <label for="exampleInputEmail1" className="usr-fn">First Name</label>
                                <input type="text" className="form-control sn-input form-grop-container" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="First Name"/>
                            </div>
                        </div>
                        <div>
                            <div className="form-group">
                                <label for="exampleInputEmail1" className="usr-fn">Last Name</label>
                                <input type="text" className="form-control sn-input form-grop-container" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Last Name"/>
                            </div>
                        </div>
                    </div>

                    <div className='form-grop-container'>
                        <div className="form-group">
                            <label for="exampleInputEmail1" className="usr-fn">Phone Number</label>
                            <input type="text" className="form-control sn-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="08012345678"/>
                        </div>
                    </div>
                    <div>
                        <div className="form-group">
                            <label for="exampleInputEmail1" className="usr-fn">Email address</label>
                            <input type="email" className="form-control sn-input" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="you@company.com"/>
                        </div>
                    </div>
                    <div>
                        <span className="usr-fn">Comment</span><br />
                        <textarea className='contact-textarea' rows="4" cols="5" placeholder=""/>
                    </div>
                    <div className="form-check Form-Check">
                        <input type="checkbox" id='form-checking'/>
                        <label className="form-check-label" for="flexCheckDefault">
                            You agree to our friendly <Link style={{color:'#027F07 ', textDecoration:'none', fontSize:'15px'}}>privacy policy</Link>.
                        </label>
                    </div>
                    <div className="form-check Form-Check">
                        
                        {/* <input type="checkbox" class="form-check-input" id="exampleCheck1"/>
                        <label className="form-check-label" for="exampleCheck1"> You agree to our friendly <Link style={{color:'#027F07 ', textDecoration:'none', fontSize:'15px'}}>privacy policy</Link>.</label> */}
                    </div>
                              <div className='contact-btn'>
                                  <h6 className='send-message'>Send Message</h6>
                              </div>
                    {/* <div className='submit-cont'>
                        <button className='submit'>Send message</button>
                    </div> */}
                    {/* </div> */}
             </form>
             <div className='contactUs-sideImg'>
                <img src={Contactus} alt='img'/>
             </div>
                </div>
            </div>
        </div>
    </div>
    <Newfooter/>
    </div>
  )
}
