import React, { useState, useEffect } from 'react';
import { useNavigate,  useLocation } from 'react-router-dom';
import OnbImg from '../Images/login.png'

import axios from 'axios';
import ActivityIndicator from '../Components/ActivityIndicator';
import AsyncStorage from '@react-native-async-storage/async-storage';
import crossedEyeIcon from '../Images/eye-slash.png';


const Signin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [error, setError] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [termsSelected, setTermsSelected] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const location = useLocation();
  
 




  const handleLogin = async () => {
    setIsLoading(true); // Set loading state to true
    try {
      const response = await axios.post('https://gtc.ogunstate.gov.ng/api/login', {
        email: email,
        password: password,
      });
       AsyncStorage.setItem('userToken', response.data.data?.token);
   
       if (location.state && location.state.from) {
        navigate(location.state.from);
      } else {
        // If there's no previous page, navigate to a default route
        navigate('/onboarding');
      }
      console.log('Login successful', response.data);
      setEmail('');
      setPassword('');
      
      
      

    } catch (error) {
      const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
      setErrorMessage(errorMessage);
      // Handle errors (e.g., display error message)
      console.error('Login failed', error);
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Login failed',
      //     text: error.response.data.message,
      //   });

    } finally {
      setIsLoading(false); // Set loading state back to false after handling the request
    }
  };

  const isButtonDisabled = !email || !password;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const navigateToSignup = () => {
    navigate('/sign_up');
  };

  const navigateToForgot = () => {
    navigate('/forgot_password');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !isButtonDisabled) {
      handleLogin();
    }
  };

  return (
    <div className='signin'>
      <div className='marketersImg'>
        <img src={OnbImg} className="leftonb-img" alt="img" />
      </div>

      <div className='sign-container'>
        <div className="sign-cont">
          {errorMessage ? (
            <div className="error-message">
              {errorMessage}
            </div>
          ) : null}
          <div id='sign-hero'>
            <h4 className='txt-h4' >Welcome back!</h4>
            <p className='txt-p'>Don't have an account? {''}<a className='anclog' onClick={navigateToSignup} style={{ cursor: 'pointer' }}>Sign Up </a></p>
          </div>
          <form>
            <div>
              <span className="st-id">Email Address</span>
              <input type="text" className="sn-input" placeholder="" onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div>
              <span className="st-id"> Password </span>
              <div className="password-input-container">
              <div className="input-container">
              <input type={showPassword ? 'text' : 'password'} className="sn-input" placeholder="" onChange={(e) => setPassword(e.target.value)} onKeyPress={handleKeyPress}/>
              </div>
              <button
                type="button"
                className="password-toggle-button"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <img src={crossedEyeIcon} alt="Hide Password" style={{ height: "20px", width: "20px" }} />
                ) : (
                  '👁️'
                )}
              </button>
            </div>
            </div>
          </form>
          {/* <button className='sgn-btn'> Proceed </button> */}
          <button className='sgn-btn' disabled={isButtonDisabled} style={{ backgroundColor: isButtonDisabled ? 'gray' : '#027f07', border: isButtonDisabled ? "1.5px solid gray" : "1.5px solid #2f945c" }} onClick={handleLogin}> {isLoading ? <ActivityIndicator /> : 'Proceed'}</button>
          <div className='' id='rsd-otp'> 
          <p onClick={() => navigateToForgot()} className='dintrcv'>Forgot Password?  <a className='sgncndt' id='clikrsnd' href=''> Recover</a></p>

            </div>


        </div>




        {/* <button onClick={continueToNextPage}>Proceed</button> */}
      </div>

    </div>


  );
}

export default Signin;