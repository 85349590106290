    import React from 'react'
import { NavLink, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const navigate = useNavigate();

    const Home = () => {
        navigate('/')
    }
    const AboutUs = () =>{
        navigate('/AboutUs')
    }
    const FAQS = () =>{
        navigate('/FAQ')
    }
    const ContactUs = () =>{
        navigate('/ContactUs')
    }
    return (

        <div className='body-footer' >
                <div className='footer-container'>
                    <div className='footer-content'>
                        <p className='ptag'>20% DISCOUNT ON ALL ORDERS</p>
                        <h1 className='htag'>Everyday fresh products</h1>
                        <p>Free delivery for order over ₦20,000, you also <br /> get best price on the market</p>
                        <div >
                            <p className='ad-headitem'>Address</p>
                            <p className='infodetls'>Oke-Mosan, Abeokuta,<br></br> Ogun State, Nigeria</p>
                            <p className='ad-headitem'>Email</p>
                            <Link className='infodetls'>info@gtc.ogunstate.gov.ng</Link>
                        </div>

                    </div>
                    <div className='otherinfo'>
                        <p className='headitem'>My account</p>
                        <p className='myakant' onClick={AboutUs}>About Us</p>
                        <p className='myakant' onClick={FAQS}>FAQS</p>
                        <p className='myakant' onClick={ContactUs}>Contact us</p>
                    </div>
    
                    <div className='otherinfo'>
                        <p className='headitem'>Site Map</p>
                        <p className='myakant'>Search Terms</p>
                        <p className='myakant'>Advanced Search</p>
                        <p className='myakant'>Our Stores</p>
                    </div>
                </div>
                <div className='footer-items'>
                    <div className='footer-icons-section'>
                        <div className='footer-icons'>
                            <i class='bx bxl-facebook-circle facebook'></i>
                            <i class='bx bxl-twitter twitter '></i>
                            <i class='bx bxl-instagram instagram'></i>
                            <i class='bx bxl-linkedin-square linkedin'></i>
                        </div>

                        <p className='c'>© 2023 Gateway Trading. All rights reserved.</p>
                        <button onClick={Home} className='footer-btn'>GTC</button>
                    </div>
                </div>
            {/* </div> */}
        </div>
    )
}
export default Footer