import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom';
// import { useState } from 'react';
import Flag from '../Images/NigeriaFlag.png';
import Avatar from "../Images/avatar-png.png"
import Logo from "../Images/icon.png"
import { UserDropdown } from '../Components/UserDropdown';
import avatar from '../Emojis/avatar-png 3.png';
// import User from '../Webpages/User';
// import { User } from './User';
import ShoppingCarts from '../Webpages/ShoppingCarts';

const NavbarHomepage = () => {
  const [showCart, setShowCart] = useState(false);
  const [openProfile, setOpenProfile] = useState(false)

  

  // const fadedBackgroundClass = cartVisible ? 'faded-background' : '';




  return (
    <div>
      <div className='header-container'>
        <div className='nav-header'>
          <div className='content'>
            <div className='contact'>
              <Link to={'#'}><i className='fa fa-phone'></i>+234 7065292789</Link>
            </div>
            <div className='center'>
              <Link to={'#'}>Get 50% Off on Selected Items</Link>
              <Link to={'#'} id='shop-now'>Shop Now</Link>
            </div>
            <div className='en-nigeria'>
              <Link to={'#'}>EN <i className='fa fa-angle-down'></i></Link>
              <Link to={'#'}><img src={Flag} alt='NigeriaFlag' style={{ paddingRight: '5px', }} /><span>Nigeria</span> <i className='fa fa-angle-down'></i></Link>
            </div>
          </div>
        </div>

        <div className='main-nav'>
          <div className='nav-logo'>
            <NavLink to={'/Home'} id='Logo'> <img src={Logo} style={{ height: "70px", width: "70px" }} alt='user' /> </NavLink>
          </div>
          <div className='nav-menu' >
            <NavLink to={'/HomePage'}>Home</NavLink>
            <NavLink to={'/Categories'}>Categories</NavLink>
            {/* <NavLink to={'/Productdetails'}>About us</NavLink> */}
            {/* <NavLink to={'/Grains'}>Contact us</NavLink> */}
          {/* </div> */}
          {/* <div className='search-section'> */}
            {/* <div className='search'>
              <button><i className='bx bx-search'></i></button>
              <input type='search' placeholder='search here..' />
            </div> */}
            <Link to={'/AboutUs'}>About Us</Link>                                 
            <Link to={'/ContactUs'}>Contact Us</Link>                                 
            <Link to={'/FAQ'}>FAQs</Link>                                 
            <Link onClick={() =>setShowCart((prev) => !prev) }>
            <i className='bx bx-cart'></i>
          </Link>
          </div>
          {/* <div className='OM'> */}
          <Link className='user' onClick={() => setOpenProfile
            ((prev) => !prev)}><img src={avatar} alt='User' /></Link>

        </div>
      </div>
      {
        openProfile && <UserDropdown />
      }
      {
      showCart&&<ShoppingCarts />
      }
    </div>
  )
}

export default NavbarHomepage