import React, { useState, useEffect, useRef } from 'react';
import { Card, Container, Row, Col, Stack, Nav, Tab, ListGroup, Button } from 'react-bootstrap';
import Newfooter from '../Components/Newfooter';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import Empty from "../Images/empty.png"
import NewnavHome from '../Components/NewnavHome';
import Newnav from '../Components/Newnav';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Activity from '../Components/Activity';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import Placeholder from 'react-bootstrap/Placeholder';
import Download from '../Images/download.svg'
import Pagination from 'react-bootstrap/Pagination';


export const SelectedCategory = () => {
    const [addToCartPressed, setAddToCartPressed] = useState(false);
    const [loadingItemId, setLoadingItemId] = useState(false);
    const [showItemAdded, setShowItemAdded] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [bearer, setBearer] = useState('');
    const [selectedSortBy, setSelectedSortBy] = useState('Sort by');
    const navigate = useNavigate();
    const location = useLocation();
    const category = location.state;
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [categoryID, setCategoryID] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        // Retrieve the category ID from localStorage
        const storedCategoryId = localStorage.getItem('selectedCategoryId');
        if (storedCategoryId) {
          setCategoryID(storedCategoryId);
        }
      }, []);
    


    const readData = async () => {
        try {

            const value2 = await AsyncStorage.getItem('userToken');

            if (value2 !== null) {
                //   console.log(value2);
                setBearer(value2);
                setAuthenticated(true);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };
    useEffect(() => {
        readData();
    }, []);

    useEffect(() => {
        // Fetch products based on the category ID
        if (categoryID) {
          setIsLoading(true);
    
          const fetchProducts = async () => {
            try {
              const response = await axios.get(`https://gtc.ogunstate.gov.ng/api/get-all-items-by-category`, {
                params: {
                  id: categoryID,
                },
              });
    
              const responseData = response.data?.data;
              console.log(responseData);
    
              setProducts(responseData);
            } catch (error) {
              console.error(error);
            } finally {
              setIsLoading(false);
            }
          };
    
          fetchProducts();
        }
      }, [categoryID]);
      

    
    const fetchAllItems = async () => {
        setLoading(true);

        try {
            const response = await axios.get('https://gtc.ogunstate.gov.ng/api/get-category-products');
            const responseData = response?.data?.data;

            // No need to group items, just set them directly
            setItems(responseData);
            // console.log(responseData);
        } catch (error) {
            console.error(error);

        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        fetchAllItems();

    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
  


    const totalItems = products.length;
    const pageCount = Math.ceil(totalItems / itemsPerPage);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleFirstPage = () => {
        setCurrentPage(1);
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < pageCount) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handleLastPage = () => {
        setCurrentPage(pageCount);
    };

   

    const displayedItems = products.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleProductClick = (product) => {
        // Navigate to the product details route, passing selected product and the first 4 category products as props
        navigate(`/product/${product.name}`, {
            state: {
                selectedProduct: product,
                categoryProducts: products.slice(0, 4), // Only the first 4 items
            },
        });
    };


    const handleSortSelection = (option) => {
        setSelectedSortBy(option);
    };

    const placeholderCount = 6;

    // const handleAddToCart = async (productId) => {
    //     setLoadingItemId(productId);
      
    //     try {
    //       // Read the current cart items from AsyncStorage
    //       const cartItems = await AsyncStorage.getItem('cartItems');
    //       const currentCart = cartItems ? JSON.parse(cartItems) : [];
      
    //       // Check if the product is already in the cart
    //       const existingCartItem = currentCart.find(item => item.productId === productId);
      
    //       if (existingCartItem) {
    //         // If the product is already in the cart, increase its quantity
    //         existingCartItem.quantity += 1;
    //       } else {
    //         // If the product is not in the cart, add it with a quantity of 1
    //         currentCart.push({ productId, quantity: 1 });
    //       }
      
    //       // Save the updated cart back to AsyncStorage
    //       await AsyncStorage.setItem('cartItems', JSON.stringify(currentCart));
      
    //       console.log(currentCart);
      
    //       setAddToCartPressed(true);
    //       setButtonDisabled(true);
      
    //       console.log('Item added to cart.');
      
    //       setShowItemAdded(true);
    //       setTimeout(() => {
    //         setShowItemAdded(false);
    //       }, 2000);
    //     } catch (error) {
    //       // Handle error as needed
    //       console.error('Error:', error);
    //     } finally {
    //       setLoadingItemId(false);
    //     }
    //   };
      

    const handleAddToCart = async (productId) => {
        setLoadingItemId(productId);
    
        try {
          
            const response = await axios.post(
                'https://gtc.ogunstate.gov.ng/api/add-to-cart',
                {
                    product_id: productId,
                    quantity: 1,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`
                    }
                }
            );
    
            setAddToCartPressed(true);
            setButtonDisabled(true);
    
            console.log('Success:', response.data.message);
    
            setShowItemAdded(true);
            setTimeout(() => {
                setShowItemAdded(false);
            }, 2000);
        } catch (error) {
            // Handle error response
            const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
            console.log('Error:', errorMessage);
    
            if (error.response && error.response.status === 401) {
               
                navigate('/sign_in'); 
            }
        } finally {
            setLoadingItemId(false);
        }
    };

    const handleCategoryClick = (itemId) => {
        localStorage.setItem('selectedCategoryId', itemId);
        navigate(`/selected-category-product/${itemId}`);
      
    };



    return (

        <div>
            {authenticated ? 
           <NewnavHome /> 
            :
            <Newnav />
}
            <div className='body' style={{ backgroundColor: 'white', }}>
                <div >
                    <div className={`item-added-banner ${showItemAdded ? '' : 'hidden'}`}>
                        Item added
                    </div>
                </div>
                <div className='main' style={{ backgroundColor: 'white', }}>
                    <Container fluid className='mainContainer'>
                        <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
                            <Row >
                                <Col lg={3}>
                                    <div className='sideNav-search'>
                                        <input type='search' placeholder='search items..' />
                                        <button><i className='bx bx-search' style={{ fontSize: '20px', color: '#667185' }}></i></button>
                                    </div>
                                    {/* <h4>{category.name}</h4> */}
                                    <ListGroup className='sideNaves' style={{ maxHeight: '300px', overflowY: 'scroll', overflowX: 'hidden' }}>
                                        {loading ? (
                                            Array.from({ length: placeholderCount }, (_, index) => (
                                                <ListGroup.Item key={index} action disabled>
                                                    <Placeholder as="p" animation="glow">
                                                        <Placeholder xs={6} />
                                                    </Placeholder>
                                                </ListGroup.Item>
                                            ))
                                        ) : (
                                            items.map((item, index) => (
                                                <ListGroup.Item
                                                    key={index}
                                                    action
                                                    onClick={() => handleCategoryClick(item.id)}
                                                    style={{
                                                        backgroundColor: item.name === item.name ? '#027f07' : 'transparent',
                                                        color: item.name === item.name ? 'white' : 'black',
                                                    }}
                                                >
                                                    {item.name
                                                        .toLowerCase()
                                                        .split(' ')
                                                        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                        .join(' ')}
                                                </ListGroup.Item>
                                            ))
                                        )}
                                    </ListGroup>

                                </Col>
                                <Col lg={9} >
                                    <div className="filter-bar d-flex flex-wrap align-items-center justify-content-between">
                                        <p className="fs-15 my-2">Showing <span className="text-black">
                                            {currentPage === pageCount
                                                ? `${(currentPage - 1) * itemsPerPage + 1} - ${totalItems} of ${totalItems} items`
                                                : `${(currentPage - 1) * itemsPerPage + 1} - ${currentPage * itemsPerPage} of ${totalItems} items`
                                            }
                                        </span></p>

                                        <Dropdown className="d-inline mx-2">
                                            <Dropdown.Toggle id="dropdown-autoclose-true">
                                                {selectedSortBy}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleSortSelection('Sort by average rating')}>Sort by average rating</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleSortSelection('Sort by new')}>Sort by new</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleSortSelection('Sort by latest')}>Sort by latest</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>


                                    </div>
                                    <Row mt={4}>
                                       
                                           
                                            {
                                                loading?(
                                                    Array.from({ length: placeholderCount }, (_, index) => (
                                                        <Col lg={4} className='lg4' key={index}>
                                                            <Card className='text-center hover-y overflow-hidden card'>
                                                                <div>
                                                                    <div id='imgs' style={{ cursor: 'default' }}>
                                                                        <Card.Img variant="top" src={Download} alt="loading..." />
                                                                    </div>
                                                                    <Card.Body>
                                                                        <Placeholder as={Card.Title} animation="glow">
                                                                            <Placeholder xs={6} />
                                                                        </Placeholder>
                                                                    </Card.Body>
                                                                </div>
                                                            </Card>
                                                        </Col>
                                                    ))
                                                    ) : displayedItems.length > 0 ? (
                                            
                                            displayedItems.map((product, index) => (
                                                <Col key={index} lg={4} className='lg4'>
                                                    <Card className='text-center hover-y overflow-hidden card'>
                                                        <div>
                                                            <div id='imgs' onClick={() => handleProductClick(product)} style={{ cursor: 'pointer' }}>
                                                                <Card.Img variant='top' src={product.image} alt={product.name
                                                                    .toLowerCase()
                                                                    .split(' ')
                                                                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                    .join(' ')} />
                                                            </div>
                                                            <Card.Body id='cardBody'>
                                                                <Card.Title>
                                                                    <Link id='productName' to={'#'}>
                                                                        {product.name
                                                                            .toLowerCase()
                                                                            .split(' ')
                                                                            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                                                            .join(' ')}
                                                                    </Link>
                                                                </Card.Title>
                                                                <Card.Text id='price'>
                                                                    ₦{Number(product.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                                </Card.Text>
                                                                <Card.Text id='rates'>
                                                                    <div>
                                                                        <i className='bx bxs-star'></i>
                                                                        <i className='bx bxs-star'></i>
                                                                        <i className='bx bxs-star'></i>
                                                                        <i className='bx bxs-star'></i>
                                                                    </div>
                                                                    <span>(2 Reviews)</span>
                                                                </Card.Text>

                                                                {loadingItemId === product.id ? (
                                                                    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                        <Activity />
                                                                    </div>
                                                                ) : (
                                                                    <Button  onClick={() => handleAddToCart(product.id)} style={{ cursor: "pointer" }} variant='success'> <i className='bx bx-cart'></i>Add To Cart</Button>
                                                                )}
                                                            </Card.Body>
                                                        </div>
                                                    </Card>
                                                </Col>
                                            ))
                                            ) : (
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                    
                                                }}>
                                                    <img src={Empty} alt='Empty' />
                                                    <p style={{ marginTop: "14px" }}>No products available in this category</p>
                                                </div>
                                        )}
                                       
                                       
                                    </Row>
                                </Col>


                            </Row>
                        </Tab.Container>



                    </Container>
                </div>
                {products.length > 0 && (
                <Pagination size='lg'>
                    <Pagination.First onClick={handleFirstPage} />
                    <Pagination.Prev onClick={handlePrevPage} disabled={currentPage === 1} />
                    {Array.from({ length: pageCount }, (_, index) => (

                        <Pagination.Item
                            key={index}
                            active={currentPage === index + 1}
                            onClick={() => handlePageClick(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={handleNextPage} disabled={currentPage === pageCount} />
                    <Pagination.Last onClick={handleLastPage} />
                </Pagination>
                  )}
            </div>
            <Newfooter />
        </div>
    )
}