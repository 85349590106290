import React, {useEffect, useState} from 'react';
import Newfooter from '../Components/Newfooter';
import Aboutus1 from '../Images/Aboutus1.png';
import Aboutus2 from '../Images/abt.jpg';
import { Button } from 'react-bootstrap';
import Team1 from '../Images/Team1.png';
import Team2 from '../Images/Team2.png';
import Team3 from '../Images/Team3.png';
import Team4 from '../Images/Team4.png';
import NewnavHome from '../Components/NewnavHome';
import Newnav from '../Components/Newnav';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const AboutUs = () => {
    const [bearer, setBearer] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    const readData = async () => {
      try {
          const value2 = await AsyncStorage.getItem('userToken');

          if (value2 !== null) {
              // console.log(value2);
              setBearer(value2);
              setAuthenticated(true);
          }
      } catch (e) {
          alert('Failed to fetch the input from storage');
      }
  };
  useEffect(() => {
      readData();
  }, []);

  return (
    <div>
       {authenticated ? 
           <NewnavHome /> 
            :
            <Newnav />
}
        <div className=' body'>
            <div className='main '>
                <div className='AboutUs'>
                    <div className='container header'>
                        {/* <p>About Us</p> */}
                        <h1>Gateway Trading Company</h1>
                        <p style={{fontSize:'20px', color:'#667085',}}>Learn more about the company and the team behind it.</p> 
                    </div>
                    <div className='container-fluid section1 sections'>
                        <div className='sideImg'>
                            <img src={Aboutus1} alt='img'/>
                        </div>
                        <div className='section-content section-cont'>
                            <div>
                                <p id='cont-p'>We’ve helped hundreds of user gets their daily needs</p>
                                <h1>We’re only just getting started on our journey</h1>
                            </div>
                            <div className='analysis'>
                                <span>
                                    <h2>400+</h2>
                                    <p>Order completed</p>
                                </span>
                                <span>
                                    <h2>600%</h2>
                                    <p>Return on investment</p>
                                </span>
                                <span>
                                    <h2>100k</h2>
                                    <p>Revenue generated</p>
                                </span>
                                <span>
                                    <h2>200+</h2>
                                    <p>5-star reviews</p>
                                </span>
                            </div>

                        </div>

                    </div>
                    <div className='container-fluid sections'>
                        <div className='sideImg sideImgs'>
                            <img src={Aboutus2} alt='img'/>
                        </div>
                        <div className='section-content section-conts'>
                            <h2>Our vision</h2>
                            <p>“To be a leading financial solutions provider in Africa by consistently delivering excellent services through an arm’s length distribution of merchants agents and channels. ”</p>
                            
                                <Button id='vission-btn' variant="success">Get Started</Button>{' '}
                            
                        </div>

                    </div>
                    <div className='container-fluid sections'>
                        <div className='section-content section-conts'>
                            <h2>Our mission</h2>
                            <p>“To be a leading financial solutions provider in Africa by consistently delivering excellent services through an arm’s length distribution of merchants agents and channels. ”</p>
                            <Button id='mission-btn' variant="success">Get Started</Button>{' '}
                        </div>
                        <div className='sideImg sideImgs'>
                            <img src={Aboutus2} alt='img'/>
                        </div>
                    </div>
                    <div className='container-fluid sections section4'>
                        <div className='our-team'>
                            <h3>Meet our team</h3>
                            <p style={{fontSize:'20px', margin:'0',}}>Our philosophy is simple — hire a team of diverse, passionate people and foster a culture that empowers you to do you best work.</p>
                        </div>
                        <div className='container-fluid sections team-profile'>
                            <span>
                                <div id='team-pic'>
                                    <img src={Team1} alt='img'/>
                                </div>
                                <div>
                                    <h6>Olivia Rhye</h6>
                                    <p id='status'>Founder & CEO</p>
                                    <p>Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</p>
                                    <div id='icons'>
                                        <i class='bx bxl-twitter'></i>
                                        <i class='bx bxl-linkedin-square'></i>
                                        <i class='bx bx-globe'></i>
                                    </div>
                                </div>
                            </span>
                            <span>
                                <div id='team-pic'>
                                    <img src={Team2} alt='img'/>
                                </div>
                                <div>
                                    <h6>Olivia Rhye</h6>
                                    <p id='status'>Founder & CEO</p>
                                    <p>Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</p>
                                    <div id='icons'>
                                        <i class='bx bxl-twitter'></i>
                                        <i class='bx bxl-linkedin-square'></i>
                                        <i class='bx bx-globe'></i>
                                    </div>
                                </div>
                            </span>
                            <span>
                                <div id='team-pic'>
                                    <img src={Team3} alt='img'/>
                                </div>
                                <div>
                                    <h6>Olivia Rhye</h6>
                                    <p id='status'>Founder & CEO</p>
                                    <p>Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</p>
                                    <div id='icons'>
                                        <i class='bx bxl-twitter'></i>
                                        <i class='bx bxl-linkedin-square'></i>
                                        <i class='bx bx-globe'></i>
                                    </div>
                                </div>
                            </span>
                            <span>
                                <div id='team-pic'>
                                    <img src={Team4} alt='img'/>
                                </div>
                                <div>
                                    <h6>Olivia Rhye</h6>
                                    <p id='status'>Founder & CEO</p>
                                    <p>Former co-founder of Opendoor. Early staff at Spotify and Clearbit.</p>
                                    <div id='icons'>
                                        <i class='bx bxl-twitter'></i>
                                        <i class='bx bxl-linkedin-square'></i>
                                        <i class='bx bx-globe'></i>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
            <Newfooter/>
    </div>
  )
}
