import React, { useState, useEffect } from 'react'
import { Navigate, useNavigate, Link, NavLink } from 'react-router-dom'
import Activity from '../Components/Activity';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Mamagold from '../Images/mamagold.jpeg';
import { Tab, Nav } from 'react-bootstrap';
import Swal from 'sweetalert2';
import Newnav from '../Components/Newnav';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import ActivityIndicator from '../Components/ActivityIndicator';
import NewnavHome from '../Components/NewnavHome';
import Newfooter from '../Components/Newfooter';
import { Placeholder, Card, Col, Button } from 'react-bootstrap';
import Download from '../Images/download.png'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';




export const ProductDetails = () => {

  const [count, setCount] = useState(1)
  const [addToCartPressed, setAddToCartPressed] = useState(false);
  const [loadingItemId, setLoadingItemId] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showItemAdded, setShowItemAdded] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [bearer, setBearer] = useState('');
  const [productId, setProductId] = useState('');
  const [selectedSimilarItem, setSelectedSimilarItem] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [product, setProduct] = useState({});
  const [otherProduct, setOtherProduct] = useState([]);
  const placeholderCount = 1;
  const location = useLocation();
  const [images, setImages] = useState([]);
  const [addToWishlistPressed, setAddToWishlistPressed] = useState(false);
  const [loadingWishlistId, setLoadingWishlistId] = useState(false);
  const [loadingWishlist, setLoadingWishlist] = useState(false);
  const [bearers, setBearers] = useState('');
  const [wishlistStatus, setWishlistStatus] = useState({});
  const [wishlistButtonText, setWishlistButtonText] = useState({});
  const [selectedItemId, setSelectedItemId] = useState(null);

  useEffect(() => {
    
    window.scrollTo(0, 0);
  }, [location]);


  useEffect(() => {
    const fetchProductDetails = async () => {
      setLoading(true);
      try {
        // Use the axios.get method with params
        const response = await axios.get(`https://gtc.ogunstate.gov.ng/api/get-item-details`, {
          params: {
            id: productId
          },
        });

        const productData = response.data?.data;
        console.log(productData);
        setProduct(productData);
        const otherProductData = productData.otherProducts;
        setOtherProduct(otherProductData.slice(0, 8));
        const prodImages = productData.images;
        setImages(prodImages.slice(0, 3));
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);




  const displayImage = () => {
    Swal.fire({
      title: product.name,
      html: `<img src="${product.image}" alt="${product.name}" style="max-width: 100%; " />`,
      focusConfirm: false,
      confirmButtonColor: "#027f07"
    });
  };

  const displayImage2 = (item) => {
    Swal.fire({
      title: item.name,
      html: `<img src="${item.image}" alt="${item.name}" style="max-width: 100%; " />`,
      focusConfirm: false,
      confirmButtonColor: "#027f07"
    });
  };

  const readData = async () => {
    try {

      const value2 = await AsyncStorage.getItem('userToken');
      const value4 = await AsyncStorage.getItem('productId');

      if (value2 !== null) {
        //   console.log(value2);
        setBearer(value2);
        setAuthenticated(true);
      }

      if (value4 !== null) {
        console.log(value4, "here");
        setProductId(value4);

      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };
  useEffect(() => {
    readData();
  }, []);





  const handleIncrement = () => {
    setCount(count + 1)
    setAddToCartPressed(false);
    setButtonDisabled(false);

  }
  const handleDecrement = () => {
    if (count > 1) {
      setCount(count - 1);
    }
  }

  let navigate = useNavigate()

  const handleAddToCart = async () => {
    setLoadingItemId(product.id);

    try {
      // Make the API request to add the item to the cart
      const response = await axios.post(
        'https://gtc.ogunstate.gov.ng/api/add-to-cart',
        {
          product_id: product.id, // Use the 'id' parameter from route.params
          quantity: count
        },

        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}` // Use the bearerToken from the state here
          }
        }

      );
      //   console.log(count, items.id)

      setAddToCartPressed(true);
      setButtonDisabled(true);

      console.log('Success:', response.data.message);
      setCount(1)
      setShowItemAdded(true);
      setTimeout(() => {
        setShowItemAdded(false);
      }, 2000);
    } catch (error) {
      // Handle error response
      const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
      console.log('Error:', errorMessage);
      if (error.response.status === 401) {

        navigate('/sign_in', { state: { from: location.pathname } });
      }
    } finally {
      setLoadingItemId(false);
    }


  };


  useEffect(() => {
    // Scroll to the top of the page when the currentPage changes
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const saveItemIdToStorage = async (itemId) => {
    try {
      await AsyncStorage.setItem('itemId', itemId);
    } catch (error) {
      console.error('Error saving itemId to AsyncStorage:', error);
    }
  };

  const handleItemClick = (item) => {
    saveItemIdToStorage(item.id);
    console.log("selected", item.id)
  }

  const handleWishlist = async () => {
    setLoadingWishlist(true);
    setSelectedItemId(product.id);
  
    try {
      const response = await axios.post(
        'https://gtc.ogunstate.gov.ng/api/add-to-wish-list',
        {
          product_id: product.id,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${bearer}`,
          },
        }
      );
  
      // Set the loading state to false and wishlist status to true only for the selected item
      setLoadingWishlist(false);
      setWishlistStatus((prevStatus) => ({
        ...prevStatus,
        [product.id]: true,
      }));
  
      // Display success message for 3 seconds
      setTimeout(() => {
        setWishlistStatus((prevStatus) => ({
          ...prevStatus,
          [product.id]: false,
        }));
      }, 3000);
    } catch (error) {
      const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
      if (error.response && error.response.status === 401) {
        navigate('/sign_in');
      }
      console.log('Error:', errorMessage);
      setLoadingWishlist(false);
    }
  };


  return (

    <div>
      {authenticated ?
        <NewnavHome />
        :
        <Newnav />
      }
      <div className='body'>
        <div >
          <div className={`item-added-banner ${showItemAdded ? '' : 'hidden'}`}>
            Item added
          </div>
        </div>
        <div className='main productdetails'>

          <div className='container'>
            <div className='lilimg'>
              {loading ? (
                Array.from({ length: placeholderCount }, (_, index) => (
                  <Col key={index}>
                    <Card >
                      <div>
                        <div id="imgs" style={{ cursor: 'default' }}>
                          <Card.Img variant="top" src={Download} alt="loading..." style={{ height: "305px", width: "305px", }} />
                        </div>
                        <Card.Body>
                          <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={6} />
                          </Placeholder>
                        </Card.Body>
                      </div>
                    </Card>
                  </Col>
                ))
              ) : (
                <img
                  src={product.image}
                  alt={product.name}
                  style={{ height: "305px", width: "305px", border: '2px solid var(--gray-4)', cursor: "zoom-in" }}
                  onClick={displayImage}
                />
              )}

              {loading ? (
                <Card.Body>
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                </Card.Body>
              ) : (
                <div className='xtraimg' style={{ cursor: "zoom-in" }}>
                  {images.map((item, index) => (
                    <li key={index} className='xtra-img'>
                      <img onClick={() => displayImage2(item)} src={item.image} style={{ width: "90px", height: "90px", border: '2px solid var(--gray-4)' }} />
                    </li>
                  ))}
                </div>
              )}
            </div>


            <div className='details' >
              {loading ? (
                <Card.Body>
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                </Card.Body>
              ) : (
                <div>
                  <h1>{product.name}</h1>
                  <p style={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
                    Description: {product.description}
                  </p>
                  {/* <span className='star-details'>
                    <div id='solid-stars'>
                      <i className='bx bxs-star'></i>
                      <i className='bx bxs-star'></i>
                      <i className='bx bxs-star'></i>
                      <i className='bx bxs-star'></i>
                      <i className='bx bxs-star'></i>
                    </div>
                    (91)
                  </span> */}
                </div>
              )}

              {loading ? (
                <Card.Body>
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                </Card.Body>
              ) : (
                <div id='Price-section'>
                  <h3 style={{ color: "#027F07" }} id='price'>₦ {Number(product.discount_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h3>
                  {product.discount_price !== product.price && (
                    <span className="mdr categories-text" >
                      <del>₦ {Number(product.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</del>
                    </span>
                  )}
                  {/* <p>Availability: <span style={{ color: "#027F07" }}>In stock</span></p> */}
                  {/* <p>Suggestion: you can divide the basket into two</p> */}
                </div>
              )}
              {/* <hr/> */}

              {loading ? (
                <Card.Body>
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                </Card.Body>
              ) : (
                <div id='quantity-section'>
                  <h6>Quantity:</h6>
                  <div className='AddSubtract-section'>
                    <div className='buttons'>
                      <button onClick={handleDecrement} id='subtract'><i class='bx bx-minus'></i></button>
                      <p className='qty-text'>{count}</p>
                      {/* <input type='text' value={count} /> */}
                      <button onClick={handleIncrement} id='add'><i class='bx bx-plus'></i></button>
                    </div>
                    {/* <p>Only <span>12 Items</span> Left! <br /> Don’t miss it</p> */}
                  </div>
                </div>
              )}

              {loading ? (
                <Card.Body>
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} />
                  </Placeholder>
                </Card.Body>
              ) : (
                loadingItemId === product.id ? (
                  <Activity />
                ) : (
                  <div className="buyNow-addToCart">
                    <button
                      onClick={handleAddToCart}
                      disabled={buttonDisabled}
                      id='buy-now'
                      style={{
                        cursor: "pointer",
                        color: addToCartPressed ? "gray" : "var(--white)",
                        backgroundColor: addToCartPressed ? "#ccc" : "#027F07",
                        border: addToCartPressed ? "#ccc" : "1px solid #027F07 ",
                        marginRight: "16px",
                      }}
                    >
                      {
                        addToCartPressed ? "In Cart" : "Add To Cart"
                      }
                    </button>
                  </div>
                )
              )}
<div 
                                       
                                       onClick={() => handleWishlist()}
                                       disabled={loadingWishlist}
                                            
                                            style={{
                                                cursor: "pointer",
                                                color: "#B92347",
                                                marginRight: "16px",
                                                fontWeight: 700,
                                                backgroundColor: "rgba(255, 53, 71, 0.1)",
                                                textAlign: 'center',
                                                padding:'8px',
                                                borderRadius: '30px',
                                                width: '194px',
                                                fontSize: '20px',
                                                marginTop: 20
                                            }}
                                        >
                                          {loadingWishlist
    ? 'Adding Item to Wishlist...'
    : wishlistStatus[product.id]
      ? 'Item Added to Wishlist!'
      : 'Add to Wishlist'}
                                       
                                    </div>

            </div>
          </div>

          {/* Description */}





          <div style={{
            alignItems: "center",
            justifyContent: "center",
            // minHeight: "50vh", 
            marginTop: 50
          }}>
            {loading ? (
              <Card.Body>
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder xs={6} />
                </Placeholder>
              </Card.Body>
            ) : (
              <Tab.Container id="tab-container" defaultActiveKey="description">
                <Nav variant="pills" color='#2F945C' id="nav-tab" role="tablist" >
                  <Nav.Item>
                    <Nav.Link eventKey="description">Description</Nav.Link>
                  </Nav.Item>

                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="description">
                    <p style={{
                      backgroundColor: "white", padding: 20, marginTop: 20,
                      borderRadius: 10, border: '2px solid var(--gray-4)',
                    }}>
                      <h3> About {product.name}</h3>
                      {product.description}
                    </p>
                  </Tab.Pane>

                </Tab.Content>
              </Tab.Container>
            )}
          </div>




          {/* Similar Items You Might Like */}
          <div className='popularSection Similar-Items' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '20vh' }}>
            <div className='popular' >
              <h1 style={{ color: "#027F07" }}>Similar Items you might like</h1>

            </div>
            <div className='popularImgs'>
              {otherProduct.map((categoryProduct, index) => (
                <Card style={{ width: '18rem', cursor: "pointer" }} key={index} >
                  <Link to="/product-details" onClick={() => handleItemClick(categoryProduct)}>
                    <div style={{ display: 'flex', justifyContent: 'center', padding: 10, position: 'relative' }}>
                      <Card.Img variant="top" src={categoryProduct.image} style={{ width: "100%", height: "186.14px", objectFit: "cover" }} />

                      <div
                        style={{
                          position: 'absolute',
                          top: '10px',
                          left: '10px',
                          backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          color: '#fff',
                          padding: '5px',
                          borderRadius: '5px',
                        }}
                      >
                        {categoryProduct.discount_type === '1' && categoryProduct.discount_percent && (
                          <span style={{ color: '#fff' }}>-{parseFloat(categoryProduct.discount_percent).toString().replace(/\.00$/, '')}%</span>
                        )}

                        {categoryProduct.discount_type === '2' && categoryProduct.flat_discount && (
                          <span style={{ color: '#fff' }}>-₦ {categoryProduct.flat_discount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        )}
                      </div>
                    </div>
                  </Link>
                  <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <Card.Body className="text-center">
                      <Card.Title style={{ fontWeight: 700 }}>{categoryProduct.name}</Card.Title>
                      <Card.Text id='price'>
                        ₦ {Number(categoryProduct.discount_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        {categoryProduct.discount_price !== categoryProduct.price && (
                          <span className="mdr categories-text" >
                            <del>₦ {Number(categoryProduct.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</del>
                          </span>
                        )}
                      </Card.Text>
                      {/* <Card.Text id='rates'>
                        <div>
                          <i className='bx bxs-star'></i>
                          <i className='bx bxs-star'></i>
                          <i className='bx bxs-star'></i>
                          <i className='bx bxs-star'></i>
                        </div>
                        <span>(2 Reviews)</span>
                      </Card.Text> */}
                    </Card.Body>
                  </div>
                </Card>


              ))}

            </div>
            <div className="btn-area">
              <div className="header-contact view-all">
                <button type="button" className="btn Order-Now"><NavLink to={"/categories"} >See More</NavLink></button>

              </div>

            </div>
          </div>
        </div>
      </div>
      <Newfooter />
    </div>
  )
}
