import React from 'react';
import { useNavigate } from 'react-router-dom';

export const UserDropdown = () => {
  const navigate = useNavigate()

const MyProfile = () =>{
  navigate('/MyProfile')
}
const logout = () =>{
  navigate ('/')
}
const ChangePassWord = () =>{
  navigate ('/ChangePassWord')
}
const AddressBook = () =>{
  navigate('/AddressBook')
}
const AboutUs = () =>{
  navigate('/AboutUs')
}
const ContactUs = () =>{
  navigate('/ContactUs')
}
const FAQs = () =>{
  navigate('/FAQ')
}
  return (
    <div className='dropdown-container'>
        <div className='user-details'>
            <h5>Oluwole Olamilesi</h5>
            {/* <p>BROOKES SOFTWARE</p> */}
            {/* <p>08012345678</p> */}
            <div id='verified'>YOU OWE: NO</div>
        </div>
        <div className='btns'>
          <button id='btn' onClick={MyProfile}><i class='bx bxs-user'></i><span>My profile</span></button>
          {/* <button id='btn'><i class='bx bxs-user'></i><span>My Order</span></button> */}
          {/* <button id='btn' onClick={AddressBook}><i class='bx bxs-book-alt'></i><span>Address Book</span></button> */}
          <button id='btn' onClick={ChangePassWord}><i class='bx bxs-edit-alt'></i><span>Change Password</span></button>
          
          {/* <button onClick={AboutUs}><i class='bx bx-log-out-circle'></i><span>About us</span></button>
          <button onClick={ContactUs}><i class='bx bx-support'></i><span>Contact us</span></button>
          <button onClick={FAQs}><i class='bx bx-question-mark'></i><span>FAQ</span></button> */}
          <button onClick={logout}><i class='bx bx-log-out-circle'></i><span>Logout</span></button>
        </div>
        
    </div>
  )
}
