import React, { useEffect, useState } from 'react'
import Logo from "../Images/GTCAPP.png"
import { NavLink, Link, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import avatar from '../Emojis/avatar-png 3.png';
import { UserDropdown } from './UserDropdown';
import Team1 from '../Images/Team1.png';
import { Badge } from 'react-bootstrap';
import { useCart } from './CartContext';



const NewnavHome = () => {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [openProfile, setOpenProfile] = useState(false)
  const cartItems = useCart();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state to control menu open/close


  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      // 

      if (value !== null) {
        // console.log("logout", value)
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };
  useEffect(() => {
    readData();
  }, []);


  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };





  return (

    <div className="header-section header-fixed">

      <div className="overlay">
        <div className="row d-flex header-area">
          <nav className="navbar navbar-expand-lg navbar-light sticky-top">
            <div id="nav-container" className='logn-nav'>
              <NavLink to={'/'} className='lgn-logo'> <img src={Logo} alt='Logo' className='nwwbp' /> </NavLink>

              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fas fa-bars"></i>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className='navbar-nav-menu' id='navmenu-logn'>
                    <li className="nav-item">
                      <NavLink to={'/'} className="nav-link " >Home</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to={'/categories'} className="nav-link"> Categories</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to={'/about_us'} className="nav-link" >About Us</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to={'/contact_us'} className="nav-link">Contact Us</NavLink>
                    </li>
                  </li>
                  <li className="nav-item" id='my-account'>
                    <NavLink to={'/my_profile'} className="nav-link">My Account </NavLink>
                  </li>
                  <li>
                    <NavLink to={'/shopping_carts'}> <i className="bx bx-cart" id='Nav-cart' ></i><Badge id="badge" bg="success">
                      {cartItems.length}
                    </Badge></NavLink>
                  </li>
                  <div className="header-ordernow">
                    <NavLink to ={'/my_profile'} id='my-profile' className='lgn-profl' ><img src={avatar} style={{height: "50px", width: "50px"}} alt='user' className='myprofile-moble' /></NavLink>
                  </div>

                </ul>
              </div>

            </div>
          </nav>
        </div>
      </div>

    </div>

  );
};
export default NewnavHome;