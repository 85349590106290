import React from 'react'
import { useNavigate } from 'react-router-dom'

export const GoBackHome = () => {
    const navigate = useNavigate();

    const GoBackHome = () =>{
        navigate ('/HomePage')
    }
  return (
    <div>
        <button className='go-back-home' onClick={GoBackHome}>
                <i class='bx bx-arrow-back'></i>
                <h5>Go Back </h5>
        </button>
    </div>
  )
}
