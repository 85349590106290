import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OnbImg from '../Images/onb-img.png';
import Divider from '../Images/Divider.png';
import GoogleIcon from '../Images/GoogleIcon.png';
import TwitterIcon from '../Images/TwitterIcon.png';
import axios from 'axios';
import ActivityIndicator from '../Components/ActivityIndicator';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';


const SignUp = () => {
  const navigate = useNavigate();
  const [ogNumber, setogNumber] = useState('');
  const [staff, setStaff] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formVisible, setFormVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [ogNumber1, setOgNumber1] = useState('');


  const isButtonDisabled = !ogNumber;

  const handlePasswordChange = (text) => {
    setPassword(text);
    setPasswordMatch(text === confirmPassword);
  };

  const handleConfirmPasswordChange = (text) => {
    setConfirmPassword(text);
    setPasswordMatch(text === password);
  };

  const isButtonDisabled2 = email.trim() === '' || password.trim() === '' || confirmPassword.trim() === '' || email.trim() === '' || phone.trim() === '';

  const HandleRegister = async () => {
    setLoading(true);
    console.log(password, confirmPassword, email, phone, ogNumber, address);
    try {

      const response = await axios.post('https://gtc.ogunstate.gov.ng/api/register', {
        password: password,
        confirm_password: confirmPassword,
        email: email,
        phone: phone,
        og_number: ogNumber1,
        address: address
      });
      Swal.fire({
        icon: 'success',
        title: 'Successful',
        text: response.data.message,
        confirmButtonText: "Proceed",
        confirmButtonColor: "#027f07"
      });

      navigate('/sign_in');
      console.log(response.data.message);




      // Clear input fields after successful registration
      setEmail('');
      setPhone('');
      setPassword('');
      setConfirmPassword('');

    } catch (error) {
      // Handle error response
      const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: error.response.data.message,
        confirmButtonText: "Continue",
        confirmButtonColor: "#027f07"
      });
      // Alert.alert('Failed', errorMessage);
      console.log('Error:', error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const HandleCheckOg = async () => {
    setIsLoading(true);
// console.log("TRIAL", ogNumber)
    try {
      const response = await axios.get('https://gtc.ogunstate.gov.ng/api/fetch-staff-record', {
        params: {
          og_number: ogNumber,
        },
      });
      const staffDetails = response.data?.data;
      console.log(staffDetails)
      setStaff(staffDetails);
      setFormVisible(true);
      setFirstName(staffDetails?.firstName || '');
      setLastName(staffDetails?.lastName || '');
      setOgNumber1(staffDetails?.ogNumber || '');
      setogNumber('');
      
      // console.log(firstName, lastName, ogNumber1)
     

    } catch (error) {
      // Handle error response
      const errorMessage = error.response?.data?.message;
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: errorMessage,
        confirmButtonText: "Go back",
        confirmButtonColor: "#027f07"
      });
      setErrorMessage(errorMessage);
      console.log('Error:', error.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToLogin = () => {
    navigate('/sign_in');
  };

  return (
    <div className='signup signin'>
      <div className='marketersImg'>
        <img src={OnbImg} className="leftonb-img" alt="img" />
      </div>


      <div className='sign-container'>
        <div className="sign-cont">
          {errorMessage ? (
            <div className="error-message">
              {errorMessage}
            </div>
          ) : null}
          {!formVisible ? (
            <div>
                  <div id='sign-hero'>
                    <h4 className='txt-h4' >Sign up!</h4>
                    <p className='txt-p'>You have an account already? <a className='anclog' onClick={navigateToLogin} style={{ cursor: 'pointer' }}>Login </a></p>
                  </div>
              <form>
                  <span className="st-id">OG Number</span>
                  
                  <input type="phone" className="sn-input" placeholder="" value={ogNumber} onChange={(e) => setogNumber(e.target.value)}/>
              </form>
                {/* <button className='sgn-btn'> Proceed </button> */}
          <button className='sgn-btn' disabled={isButtonDisabled} style={{ backgroundColor: isButtonDisabled ? 'gray' : '#027f07', border: isButtonDisabled ? "1.5px solid gray" : "1.5px solid #2f945c" }} onClick={HandleCheckOg}> {isLoading ? <ActivityIndicator /> : 'Proceed'}</button>
          {/* <div className='tandc'>
            <div>
              <input type='checkbox' className='tandc-radio'></input></div>
            <div>
              <p>I Agree to the <a className='sgntrm' href=''>Terms </a  > and <a className='sgncndt' href=''> Conditions</a></p>

            </div>

          </div> */}
          {/* <div>
            <img src={Divider} className="divider" alt="divider" />
          </div> */}
          {/* <div className='socials'>
            <div className='socialBx google'>
              <img src={GoogleIcon} alt='GoogleIcon' />
              <h5>Google</h5>
            </div>
            <div className='socialBx google'>
              <img src={TwitterIcon} alt='TwitterIcon' />
              <h5>Twitter</h5>
            </div>
          </div> */}
        </div>
        ) : (
        <div className="usr-cont1">
          <h4 className='profile-text' >Enter your details!</h4>
          {/* <p > Enter your details to continue with your registration!</p> */}
          {/* <div className='profile'>
                    <div id='profilePic-cont'></div>
                    <img src={Imgicon} className="img-icon" alt="img" />
                </div> */}

          <form>
            <div id='userMainInput'>
              <div>
                <span className="usr-fn">First Name</span><br />
                <input type="text" className="usr-input" placeholder="Enter your first name" value={firstName}  />
              </div>
              <div>
                <span className="usr-fn">Last Name</span><br />
                <input type="text" className="usr-input" placeholder="Enter your last name" value={lastName} readOnly={true}  />
              </div>
            </div>

            <div id='userMainInput'>
              <div>
                <span className="usr-fn">OG Number</span><br />
                <input type="text" className="usr-input" placeholder="Enter your Og number" value={ogNumber1}  readOnly={true} />
              </div>
              <div>
                <span className="usr-fn">Password</span><br />
                <input type="password" className="usr-input" placeholder="Create your password" value={password} onChange={(e) => handlePasswordChange(e.target.value)} />
              </div>
            </div>
            <div id='userMainInput'>
              <div>
                <span className="usr-fn">Confirm Password</span><br />
                <input type="password" className="usr-input" placeholder="Confirm your password" value={confirmPassword} onChange={(e) => handleConfirmPasswordChange(e.target.value)} />
              </div>
              {/* {!passwordMatch && 
                        <div style={{marginLeft: 90, marginTop: -25, color: "#F64E60"}}>Passwords do not match</div>} */}


              <div>
                <span className="usr-fn">Address</span><br />
                <input type="text" className="usr-input" placeholder="Enter your address" value={address} onChange={(e) => setAddress(e.target.value)} />
              </div>
            </div>
            <div id='userMainInput'>
              <div>
                <span className="usr-fn">Email</span><br />
                <input type="text" className="usr-input" placeholder=" Enter your valid email" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>

              <div>
              <span className="usr-fn">Phone number</span><br />
                <input type="text" className="usr-input" placeholder="Enter your phone number" value={phone} onChange={(e) => setPhone(e.target.value)} />
              </div>
            </div>
          </form>

          <button className='sgn-btn' disabled={isButtonDisabled2} style={{ backgroundColor: isButtonDisabled2 ? 'gray' : '#2F945C', border: isButtonDisabled2 ? "1.5px solid gray" : "1.5px solid #2f945c" }} onClick={HandleRegister}>{loading ? <ActivityIndicator /> : 'Proceed'}</button>
     </div>
                    )}
        </div>



      </div>

    </div>


  );
}

export default SignUp;