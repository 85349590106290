import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OnbImg from '../Images/onb-img.png';
import Divider from '../Images/Divider.png';
import GoogleIcon from '../Images/GoogleIcon.png';
import TwitterIcon from '../Images/TwitterIcon.png';
import axios from 'axios';
import ActivityIndicator from '../Components/ActivityIndicator';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import AsyncStorage from '@react-native-async-storage/async-storage';


const Setpassword = () => {
  const navigate = useNavigate();
  
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  



  

  const isButtonDisabled2 = password.trim() === '' || confirmPassword.trim() === '' ;

  const readData = async () => {
    try {

        const value1 = await AsyncStorage.getItem('email');

        if (value1 !== null) {
            //   console.log(value2);
            setEmail(value1);
        }
       
        
    } catch (e) {
        alert('Failed to fetch the input from storage');
    }
};
useEffect(() => {
    readData();
}, []);

  const HandleRegister = async () => {
    setLoading(true);
    console.log(password, confirmPassword, email);
    try {

      const response = await axios.post('https://gtc.ogunstate.gov.ng/api/create-new-password-with-otp', {
        password: password,
        password_confirmation: confirmPassword,
        email: email,
        otp: otp
        
      });

      navigate('/sign_in');
      console.log(response.data.message);




      // Clear input fields after successful registration
      setEmail('');
      setOtp('');
      setPassword('');
      setConfirmPassword('');

    } catch (error) {
      // Handle error response
      const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: error.response.data.message,
        confirmButtonText: "Continue",
        confirmButtonColor: "#027f07"
      });
      // Alert.alert('Failed', errorMessage);
      console.log('Error:', error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  

  const navigateToLogin = () => {
    navigate('/sign_in');
  };

  return (
    <div className="signup signin">
  <div className="marketersImg">
    <img src={OnbImg} className="leftonb-img" alt="img" />
  </div>

  <div className="sign-container">
    <div className="sign-cont">
      {errorMessage && (
        <div className="error-message">{errorMessage}</div>
      )}

      <div>
        <div id="sign-hero">
          <h4 className="txt-h4">Set new password!</h4>
          <p className="txt-p">
            Your new password must be different from the old one{" "}
            <a className="anclog" onClick={navigateToLogin} style={{ cursor: "pointer" }}>
              Login
            </a>
          </p>
        </div>
        <form>
          <span className="st-id">Password</span>
          <input
            type="password"
            id="fgt-pwd"
            placeholder="Enter your new password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </form>
        <form id="conf-pswd">
          <span className="st-id">Confirm Password</span>
          <input
            type="password"
            id="fgt-pwd"
            placeholder="Enter your new password again"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </form>
        <form id="conf-pswd">
          <span className="st-id">Enter OTP</span>
          <input
            type="text"
            id="fgt-pwd"
            placeholder="Enter the OTP sent to you"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          />
        </form>
        <button
          className="sgn-btn"
          // disabled={isButtonDisabled}
          style={{
            // backgroundColor: isButtonDisabled ? "gray" : "#027f07",
            // border: isButtonDisabled ? "1.5px solid gray" : "1.5px solid #2f945c",
          }}
          // onClick={HandleCheckOg}
        >
          {isLoading ? <ActivityIndicator /> : "Reset password"}
        </button>
        <div className="" id="rsd-otp">
          <p className="dintrcv">
            Didn't receive the email?{" "}
            <a className="sgncndt" id="clikrsnd" href="">
              Click to Resend
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>



  );
}

export default Setpassword;