import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import Newfooter from '../Components/Newfooter';
import Herobg from '../Images/banner-bg.png'
import Bannerslider from '../Images/banner-slider-1.png'
import { Placeholder, Card, Col } from 'react-bootstrap';
import Download from '../Images/download.png'
import AsyncStorage from '@react-native-async-storage/async-storage';
// import Aboutus from '../Images/bg.jpg';
import Fresh from '../Images/bg2.png';
import Newnav from '../Components/Newnav';
import NewnavHome from '../Components/NewnavHome';
import axios from 'axios';
import Activity from '../Components/Activity';
// import Carousel from 'react-bootstrap/Carousel';
import { Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import WishlistIcon from '../Images/wishlist.png';





export const Onboarding = () => {

    const [items, setItems] = useState([]);
    const [productId, setProductId] = useState([]);
    const [loading, setLoading] = useState(false);
    const itemsPerPage = 6;
    const [currentPage, setCurrentPage] = useState(1);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [currentIndex, setCurentIndex] = useState(0);
    const [addToCartPressed, setAddToCartPressed] = useState(false);
    const [addToWishlistPressed, setAddToWishlistPressed] = useState(false);
    const [loadingItemId, setLoadingItemId] = useState(false);
    const [loadingWishlistId, setLoadingWishlistId] = useState(false);
    const [loadingWishlist, setLoadingWishlist] = useState(false);
    const [bearer, setBearer] = useState('');
    const [bearers, setBearers] = useState('');
    const [wishlistStatus, setWishlistStatus] = useState({});
    const [wishlistButtonText, setWishlistButtonText] = useState({});
const [ratings, setRatings] = useState([]);



    const [showItemAdded, setShowItemAdded] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const navigate = useNavigate();

    const navigateToCategories = () => {
        navigate('/Categories')
    };
    const [authenticated, setAuthenticated] = useState(false);
    const readData = async () => {
        try {
            const value2 = await AsyncStorage.getItem('userToken');

            if (value2 !== null) {
                // console.log(value2);
                setBearer(value2);
                setAuthenticated(true);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };
    useEffect(() => {
        readData();
    }, []);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };

    // const startIndex = (currentPage - 1) * itemsPerPage;
    // const endIndex = startIndex + itemsPerPage;

    // const itemsToDisplay = items.slice(startIndex, endIndex);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const placeholderCount = 3;

    


    const fetchAllItems = async () => {
        setLoading(true);

        try {
            const response = await axios.get('https://gtc.ogunstate.gov.ng/api/get-category-products');
            const responseData = response.data?.data;
            const products = responseData.map(item => item?.products).flat();
            const productsss = products.map(item => item?.ratings).flat();
            setRatings(productsss);
            setItems(responseData);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllItems();
    }, []);




    const handleAddToCart = async (productId) => {
        // Set loading and selected item ID
        setLoadingItemId(productId);
        setSelectedItemId(productId);


        try {
            // Make an API request to add the item to the cart
            const response = await axios.post(
                'https://gtc.ogunstate.gov.ng/api/add-to-cart',
                {
                    product_id: productId,
                    quantity: 1,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`,
                    }
                }
            );

            // Update UI state
            setAddToCartPressed(true);
            setButtonDisabled(true);

            // Show a confirmation message and reset loading state
            setShowItemAdded(true);
            setTimeout(() => {
                setShowItemAdded(false);
                setLoadingItemId(null);
            }, 2000);
        } catch (error) {
            // Handle error response
            const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
            if (error.response && error.response.status === 401) {

                navigate('/sign_in');
            }
            console.log('Error:', errorMessage);
            setLoadingItemId(null);
        }


    };

    const handleWishlist = async (productId) => {
      setLoadingWishlist(true);
      setSelectedItemId(productId);
    
      try {
        const response = await axios.post(
          'https://gtc.ogunstate.gov.ng/api/add-to-wish-list',
          {
            product_id: productId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${bearer}`,
            },
          }
        );
    
        // Set the loading state to false and wishlist status to true only for the selected item
        setLoadingWishlist(false);
        setWishlistStatus((prevStatus) => ({
          ...prevStatus,
          [productId]: true,
        }));
    
        // Display success message for 3 seconds
        setTimeout(() => {
          setWishlistStatus((prevStatus) => ({
            ...prevStatus,
            [productId]: false,
          }));
        }, 3000);
      } catch (error) {
        const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
        if (error.response && error.response.status === 401) {
          navigate('/sign_in');
        }
        console.log('Error:', errorMessage);
        setLoadingWishlist(false);
      }
    };
    
    

    const saveItemIdToStorage = async (itemId) => {
        try {
            await AsyncStorage.setItem('itemId', itemId);
        } catch (error) {
            console.error('Error saving itemId to AsyncStorage:', error);
        }
    };

    const handleItemClick = (item) => {
        saveItemIdToStorage(item.id);
    }


    const calculateAverageRating = (productRatings) => {
      if (!productRatings || productRatings.length === 0) {
        return 0; // Default to 0 if no ratings
      }
    
      const totalRating = productRatings.reduce((acc, rating) => acc + parseFloat(rating.rating), 0);
      const averageRating = totalRating / productRatings.length;
      // console.log(averageRating, "addkdk");
      return averageRating;
    };
    

    

    return (

        <div>
            {authenticated ?
                <NewnavHome />
                :
                <Newnav />
            }

            <div classNameName='body'>
                <div >
                    <div className={`item-added-banner ${showItemAdded ? '' : 'hidden'}`}>
                        Item added
                    </div>
                </div>
                <div classNameName='main'>

                    <div>
                        <div>
                            <section className="banner-section index" id="home">
                                <div className="banner-carousel">
                                    <div className="carousel-single" style={{ width: '100%', display: 'inline-block' }}>
                                        <div className="banner-content">
                                            <div className="container position-relative">
                                                <div className="row justify-content-between align-items-center">
                                                    <div className="col-xxl-6 col-lg-6">
                                                        <img src={Herobg} alt="" className='max-un' />

                                                        <div className="main-contentnew">
                                                            <div className="top-area section-text" id='hero-content'>
                                                                <div>
                                                                    <h1 className="title" style={{ fontSize: '76px', fontWeight: '700' }}>Gateway<br></br> Trading<br></br> Company</h1>
                                                                    <p className="xlr">Your One-Stop Shop for Quality Products, Goods, Foodstuffs, and More! </p>



                                                                </div>
                                                                <img src={Bannerslider} alt="" className='bannerslider-bag' />

                                                            </div>

                                                        </div>
                                                    </div>


                                                    <div className="col-xxl-6 col-lg-5 text-end">
                                                        <div className="sec-img"  >
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>
                        </div>
                        {/* <!-- Banner Section end --> */}



                        {/* <!-- Shop start --> */}

                        <section className="shop-section" id="shop">
  <div className="overlay pt-120 pb-120">
    <div className="container wow fadeInUp">
      <div className="row justify-content-center">
        <div className="col-lg-6 text-center">
          <div className="section-header">
            <h2 className="title" style={{ fontSize: '57px' }}>All Our Products</h2>
            <p>Discover Quality Household Products, Goods, and Foodstuffs, All in One Place!</p>
          </div>
        </div>
      </div>

      {items
        .filter((category) => category.products && category.products.length > 0)
        .map((category, index) => (
          <div key={index}>
            <div style={{width: "100%", backgroundColor: "green", padding: 10, marginBottom: 20, borderRadius: 5}}>
            <h3 style={{color: "#fff"}}>{category.name
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}</h3>
            </div>
            <div className="row features-section">
              {loading
                ? Array.from({ length: 6 }, (_, index) => (
                    <Col key={index} lg={4} className="lg4">
                      <Card className="text-center hover-y overflow-hidden card">
                        <div>
                          <div id="imgs" style={{ cursor: 'default' }}>
                            <Card.Img variant="top" src={Download} alt="loading..." />
                          </div>
                          <Card.Body>
                            <Placeholder as={Card.Title} animation="glow">
                              <Placeholder xs={6} />
                            </Placeholder>
                          </Card.Body>
                        </div>
                      </Card>
                    </Col>
                  ))
                : category.products.slice(0, 6).map((product, productIndex) => (
                    <div key={productIndex} className="col-xl-4 col-lg-6 col-9">
                      <div className="single-box">
                        <div className="img-area">
                          <Link to="/product-details">
                            <div style={{ position: 'relative' }}>
                              <img
                                src={product.image}
                                alt={product.name}
                                className="product2"
                                style={{ width: '356px', height: '251.88px' }}
                                onClick={() => handleItemClick(product)}
                              />
                              
                              {product.discount_type && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    top: '10px',
                                    left: '10px',
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    color: '#fff',
                                    padding: '5px',
                                    borderRadius: '5px',
                                  
                                  }}
                                >
                                  {product.discount_type === '1' && product.discount_percent && (
                                    <span style={{ color: '#fff' }}>
                                      -{parseFloat(product.discount_percent).toString().replace(/\.00$/, '')}%
                                    </span>
                                  )}

                                  {product.discount_type === '2' && product.flat_discount && (
                                    <span style={{ color: '#fff' }}>-₦ {product.flat_discount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                  )}
                                </div>
                              )}
                            </div>
                          </Link>
                        </div>
                        <div className="bottom-area">
                          <div className="text-area">
                            <h5
                              style={{
                                fontSize: '24px',
                                fontWeight: '700',
                                maxHeight: '1.5em',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {product.name
                                .toLowerCase()
                                .split(' ')
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(' ')}
                            </h5>
                            <div className="price-rating d-flex align-items-center justify-content-between flex-wrap gap-2">
                              <div className="price-area d-flex align-items-center gap-2">
                                <h6 className="cur-price" style={{ fontSize: '18px', fontWeight: '700' }}>
                                  ₦ {Number(product.discount_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </h6>
                                {product.discount_price !== product.price && (
                                  <span className="mdr">
                                    <del>₦ {Number(product.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</del>
                                  </span>
                                )}
                              </div>

                              <div className="rating-area">
  {calculateAverageRating(product.ratings) > 0 ? (
    Array.from({ length: 5 }, (_, index) => (
      <span key={index}>
        {index < Math.floor(calculateAverageRating(product.ratings)) ? (
          <i className="fas fa-star" style={{ color: 'gold' }}></i>
        ) : index - Math.floor(calculateAverageRating(product.ratings)) === 0.5 ? (
          <i className="fas fa-star-half-alt" style={{ color: 'gold' }}></i>
        ) : (
          <i className="far fa-star" style={{ color: 'gold' }}></i>
        )}
      </span>
    )).slice(0, 5) // Ensure only up to 5 stars are displayed
  ) : null}
</div>








                            </div>
                          </div>
                          <div className="footer-area d-flex gap-3">
      {authenticated ? (
        loadingItemId === product.id ? (
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Activity />
          </div>
        ) : (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Button
              id="add-to-cart-btn"
              onClick={() => handleAddToCart(product.id)}
              style={{ cursor: 'pointer', marginBottom: '8px', width: '100%' }}
              variant="success"
            >
              <i className="bx bx-cart"></i>Add To Cart
            </Button>
            <Button
  id="wishlist-btn"
  onClick={() => handleWishlist(product.id)}
  style={{
    cursor: 'pointer',
    width: '100%',
    color: '#B92347',
    fontWeight: 700,
  }}
  variant="danger"
  disabled={loadingWishlist}
>
  {loadingWishlist[product.id]
    ? 'Adding Item to Wishlist...'
    : wishlistStatus[product.id]
      ? 'Item Added to Wishlist!'
      : 'Add to Wishlist'}
</Button>


          </div>
        )
      ) : (
        <Button
          id="add-to-cart-btn"
          onClick={() => handleAddToCart()}
          style={{ cursor: 'pointer', width: '100%' }}
          variant="success"
        >
          Order Now!
        </Button>
      )}
    </div>

                        </div>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        ))}
    </div>
  </div>
  <div className="btn-area">
    <div className="header-contact view-all">
      <button type="button" className="btn Order-Now"><NavLink to="/categories">See More</NavLink></button>
    </div>
  </div>
</section>



                        {/* <!-- About Us start --> */}
                        <section className="about-us" style={{ backgroundImage: `url(${Fresh})` }}>
                            <div className="overlay pt-120 pb-120">
                                <div className="container wow fadeInUp">
                                    <div className="row justify-content-between align-items-center">
                                        <div className="col-lg-6">
                                            <div className="section-text">
                                                <h2 className="title" style={{ fontSize: '57px' }}>Fresh <br />Organic Food</h2>
                                                <p>Unbeatable Offer: Save Up to 70% on Fresh <br /> Organic Food and Groceries!</p>
                                            </div>
                                            <div className="btn-area">
                                                <div className="header-contact">
                                                    <button type="button" className="btn Order-Now"><NavLink to={"/contact_us"} >Contact Us</NavLink></button>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* <!-- About Us end --> */}
                    </div>

                </div>
            </div>
            <Newfooter />
        </div>
    )
}