import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OnbImg from '../Images/onb-img.png';
import Divider from '../Images/Divider.png';
import GoogleIcon from '../Images/GoogleIcon.png';
import TwitterIcon from '../Images/TwitterIcon.png';
import axios from 'axios';
import ActivityIndicator from '../Components/ActivityIndicator';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import AsyncStorage from '@react-native-async-storage/async-storage';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [ogNumber, setogNumber] = useState('');
  const [staff, setStaff] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formVisible, setFormVisible] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [ogNumber1, setOgNumber1] = useState('');


  const isButtonDisabled = !email;

  const handlePasswordChange = (text) => {
    setPassword(text);
    setPasswordMatch(text === confirmPassword);
  };

  const handleConfirmPasswordChange = (text) => {
    setConfirmPassword(text);
    setPasswordMatch(text === password);
  };

  

  
  const handleForgotPassword = async () => {
    setIsLoading(true);
    
    try {
      const response = await axios.post(
        'https://gtc.ogunstate.gov.ng/api/forgot-password',
        { email: email },
      );
      await AsyncStorage.setItem('email', email);
      console.log(email)
      const result = response?.data?.message;
      console.log(result);
      navigate('/set_password')
      setEmail("");
         } catch (error) {
      console.log(error);
      Swal.fire({
        icon: 'error',
        title: 'Failed',
        text: error.response.data.message,
        confirmButtonText: "Continue",
        confirmButtonColor: "#027f07"
      });
    } finally {
      setIsLoading(false);
    }
  };



  return (
    <div className='signup signin'>
      <div className='marketersImg'>
        <img src={OnbImg} className="leftonb-img" alt="img" />
      </div>


      <div className='sign-container'>
        <div className="sign-cont">
          {errorMessage ? (
            <div className="error-message">
              {errorMessage}
            </div>
          ) : null}

          <div>
            <div id='sign-hero'>
              <h4 className='txt-h4' >Fogot Password?</h4>
              <p className='txt-p'>No Worries, we’ll send you some instructions </p>
            </div>
            <form>
              <span className="st-id">Email</span>
              <input type="text" className="sn-input" placeholder="Enter your email to continue" onChange={(e) => setEmail(e.target.value)} />
            </form>
            
            <button onClick={() => navigate('/set_password')} className='sgn-btn' disabled={isButtonDisabled} style={{ backgroundColor: isButtonDisabled ? 'gray' : '#027f07', border: isButtonDisabled ? "1.5px solid gray" : "1.5px solid #2f945c" }} >Proceed</button>
            {/* <button className='sgn-btn' disabled={isButtonDisabled} style={{ backgroundColor: isButtonDisabled ? 'gray' : '#027f07', border: isButtonDisabled ? "1.5px solid gray" : "1.5px solid #2f945c" }} > {isLoading ? <ActivityIndicator /> : 'Proceed'}</button> */}


          </div>




        </div>

      </div>
      </div>


      );
}

      export default ForgotPassword;