import React from 'react'
import { NavLink, Link } from 'react-router-dom';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import Newsletter from "../Images/newsletter-bg.png"
import Logowhite from "../Images/gtcapp-white.png"
import Iconsend from "../Images/icons/send.png"
import { Button } from 'react-bootstrap';
const Newfooter = () => {

    const navigate = useNavigate();

    const handleOrderNow = () => {
        navigate('/categories')
    }
    return (

<div>
        <section className="newsletter">
        <div className="overlay pt-120">
            <div className="container wow fadeInUp"  >
                <div className="main-content" style={{ backgroundImage: `url(${Newsletter})` }} >
                {/* <img src="/Images/newsletter-bg.png" alt='Newsletter' classNameName='nwsltr' /> */}

                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-6">
                            <div className="section-area">
                                <h2 className="title" id='fotr-title' >No waiting, Order right now!</h2>
                                <p>And get 10%off your first order</p>
                            </div>
                            
                        </div>
                        <div className="col-lg-6 text-lg-end mt-lg-0 mt-4">
                            <Button id='footer-btn' onClick={handleOrderNow} className="cmn" variant="success">Order Now</Button>
                            {/* <div className="btn-area">
                                <a href="#" className="cmn-btn">Order Now</a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

<footer className="footer-section pt-120">
<div className="footer-area dark-sec pt-120">
    <div className="container" >
        <div className="row wrapper" id='row-wrapper'>
            <div className="col-xl-3 col-md-5">
                <div className="single-area">
                   <NavLink to={'/'}><img src={Logowhite} alt='Logo' classNameName='nwwhtwbp'/></NavLink>
                    <p className="mt-4">Your One-Stop Shop for Quality Products, Goods, Foodstuffs, and More! </p>
                    <div className="social mt-4">
                        <ul className="d-flex gap-4">
                            <li><a href="javascript:void(0)"><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href="javascript:void(0)"><i className="fab fa-instagram"></i></a></li>
                            <li><a href="javascript:void(0)"><i className="fab fa-linkedin-in"></i></a></li>
                            <li><a href="javascript:void(0)"><i className="fab fa-twitter"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-xl-3 col-md-3">
                {/* <div className="single-area">
                    <h4 style={{ fontSize: '32px', fontWeight: '700'  }}>Quick Links</h4>
                    <ul className="items">
                        <li><a href="#">Home</a></li>
                        <li><a href="#">About us</a></li>
                        <li><a href="#">Shop</a></li>
                        <li><a href="#">Contact</a></li>
                    </ul>
                </div> */}
            </div>
            <div className="col-xl-3 col-md-3">
                <div className="single-area">
                    <h4 style={{ fontSize: '32px', fontWeight: '700'  }}>Office</h4>
                    <div className="contact-location">
                        <p>
                            <span>+234 7065 292 789</span>
                        </p>
                        <p>
                            <span><a href="" className="f0tremail" data-cfemail="6d09080c03030c430e181f19041e2d08150c001d0108430e0200">[email&#160;protected]</a></span>
                        </p>
                        <p>Oke-Mosan, Abeokuta, Ogun State, Nigeria</p>
                    </div>
                    <NavLink to={'/contact_us'} className='mailfotr' >Email us</NavLink>
                </div>
            </div>
            <div className="col-xl-3 col-md-5">
                <div className="single-area">
                    <h4 style={{ fontSize: '32px', fontWeight: '700'  }}>Newsletter</h4>
                    <p>Subscribe our newsletter to get our latest update & news</p>
                    <form action="#" className="mt-4">
                        <div className="form-group input-area d-flex align-items-center">
                            <input type="text" placeholder="Your Mail Adress" autocomplete="off"/>
                            <button><img src={Iconsend} alt='Logo' classNameName='send'/>
</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div className="footer-bottom">
        <div className="container">
            <div className="row gap-4 gap-md-0 justify-content-between align-items-center">
                <div className="col-md-6 order-md-0 order-1 p-0 ">
                    <div className="copyright text-md-start text-center">
                        <p>Copyright © 2023 <a href="index.html" className='gtcfootr'>Gateway Trading</a>. All rights reserved.</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <ul className=" footer-d-flex d-flex flex-wrap gap-2 justify-content-md-end justify-content-center">
                        <li>Support</li>
                        <li>Privacy</li>
                        <li>Terms &amp; Condition</li>
                        <NavLink to={'/faq'} className='faqfotr'> <li>FAQs</li></NavLink>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
</footer>
</div>
)
}
export default Newfooter