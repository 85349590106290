import React, { useState, useEffect } from 'react'
import Atarodo from '../Images/Atarodo.png';
import { useNavigate } from 'react-router-dom';
import { Mininavthree } from '../Components/Mininavthree';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Empty from "../Images/empty.png"
import Footer from '../Components/Footer';
import Success from '../Images/success1.jpg';
import { Card, Container, Row, Col, Stack, Nav, Tab, ListGroup,  } from 'react-bootstrap';
import Placeholder from 'react-bootstrap/Placeholder';
import Download from '../Images/download.png'
import NewnavHome from '../Components/NewnavHome';
import Swal from 'sweetalert2';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ActivityIndicator from '../Components/ActivityIndicator';
import Newfooter from '../Components/Newfooter';
import Newnav from '../Components/Newnav';

import Button from 'react-bootstrap/Button';

export default function ShoppingCarts() {
  const [cartItems, setCartItems] = useState([]);
  // const [stores, setStores] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [amount, setAmount] = useState([]);
  // const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState([]);
  const [taxAmount, setTaxAmount] = useState([]);
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [itemQuantities, setItemQuantities] = useState([]);
  const [prodID, setProdID] = useState([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [deliveryInstruction, setDeliveryInstruction] = useState('');
  const [selectedAddress, setSelectedAddress] = useState('');
  const [selectedStoreId, setSelectedStoreId] = useState('');
  const navigate = useNavigate();
  const [selectedPayment, setSelectedPayment] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [selectedStoreText, setSelectedStoreText] = useState('');
  const [stores, setStores] = useState([]);
  const [memberDetails, setMemberDetails] = useState([]);
  const [email, setEmail] = useState('');
  const [user, setUser] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
const [totalTax, setTotalTax] = useState('')
  const [firstname, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

 
  const [selectedStore, setSelectedStore] = useState(null);
  
  const [authenticated, setAuthenticated] = useState(false);

  const fetchMemberDetails = async () => {
    try {
      const response = await axios.get(`https://gtc.ogunstate.gov.ng/api/profile`, { headers });
      const details = response?.data?.data;
      const userDetail = details.firstname + " " + details.lastname;
      setMemberDetails(details);
      setFirstName(details.firstname)
      setLastName(details.lastname)
      setPhone(details.phone_number)
      setAddress(details.address)
      const addresses = [details.address, details.address_1, details.address_2];
      setAddresses(addresses);
      setEmail(details.email)
      setUser(userDetail)


      // console.log(memberDetails)

      // console.log(email)

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching member details, regardless of success or error
    }
  };

  useEffect(() => {
    readData();
    if (bearer) {
      setIsLoading(true);
      fetchMemberDetails();


    }
  }, [bearer]);


 
  const handleDelivery = (event) => {
    setDeliveryMethod(event.target.value);
  };

  let Navigate = useNavigate()

  const readData = async () => {
    try {
      const value2 = await AsyncStorage.getItem('userToken');

      if (value2 !== null) {
        // console.log(value2);
        setBearer(value2);
        setAuthenticated(true);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };
  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const handleOptionChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  const handleStoreSelect = (store) => {
    setSelectedStoreText(store.store_address);
    setSelectedStoreId(store.store_id);
  };

  const handleDeleteCart = async (productId) => {
    try {
      const response = await axios.get(
        `https://gtc.ogunstate.gov.ng/api/delete-customer-cart?id=${productId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          },
        }
      );
      console.log('Success:', response.data.message);
      setCartItems(prevCartItems => prevCartItems.filter(item => item.id !== productId));
    } catch (error) {
      const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
      console.log('Error:', errorMessage);
      //   Alert.alert('Failed', errorMessage);
    }
  };

  const maxLength = 30;
  const calculateTotalPrice = () => {
    let total = 0;
    cartItems.forEach((item, index) => {
      total += item.product.discount_price * itemQuantities[index];
    });
    const formattedTotal = total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    // setTotal(formattedTotal)
    return formattedTotal;
  };

 
  
  const calculateTotalTax = () => {
    let totalTax = 0;
    cartItems.forEach((item, index) => {
      const taxPercentage = item.product?.tax || 0; // Use 0 if tax is not present
      const discountedPrice = item.product?.discount_price || 0; // Use 0 if discount_price is not present
      const taxPerItem = (taxPercentage / 100) * discountedPrice * itemQuantities[index];
      totalTax += taxPerItem;
    });
    console.log(totalTax);
    return totalTax.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };

  
  const calculateFinalTotal = () => {
    const totalPricee = Number(calculateTotalPrice().replace(/,/g, ''));
    const totalTaxx = Number(calculateTotalTax().replace(/,/g, ''));
    // const deliveryFeeValue = Number(deliveryFee.replace(/,/g, ''));
  
    const finalTotal = totalPricee + totalTaxx ;
  
  
    const formattedFinalTotal = finalTotal.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  
    return formattedFinalTotal;
  };
  
  
  
  

  const handleIncrement = async (index) => {
    const newQuantities = [...itemQuantities];
    newQuantities[index] = newQuantities[index] + 1;
    await setItemQuantities(newQuantities);
  
    // Wait for the state to be updated before calling handleAddToCart
    await handleAddToCart(product[index], newQuantities[index]);
  
    // Recalculate total tax with updated quantities
    const updatedTotalTax = calculateTotalTax();
    setTotalTax(updatedTotalTax);
  };
  
  const handleDecrement = async (index) => {
    if (itemQuantities[index] > 1) {
      const newQuantities = [...itemQuantities];
      newQuantities[index] = newQuantities[index] - 1;
      await setItemQuantities(newQuantities);
  
      // Wait for the state to be updated before calling handleAddToCart
      await handleAddToCart(product[index], newQuantities[index]);
  
      // Recalculate total tax with updated quantities
      const updatedTotalTax = calculateTotalTax();
      setTotalTax(updatedTotalTax);
    }
  };
  


  const handleAddToCart = async (product, itemQuantities) => {
    try {
      // Make the API request to add the item to the cart
      const response = await axios.post(
        'https://gtc.ogunstate.gov.ng/api/add-to-cart',
        {
          product_id: product,
          quantity: itemQuantities
        },

        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}` // Use the bearerToken from the state here
          }
        }

      );

      // console.log(count, product )
      console.log('Success:', response.data.message);

    } catch (error) {
      // Handle error response
      const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
      console.log('Error:', error.response?.data?.message);
      // Alert.alert('Failed', errorMessage);
    }

  };


  const fetchAllOrders = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(`https://gtc.ogunstate.gov.ng/api/customer-cart`,
        {
          headers,
        }
      );
      console.log(cartItems)
      const result = response?.data?.data;
      setCartItems(result);
      //   const prodIds = result.product.id;
      //   setProdID(prodIds);
      const initialQuantities = result.map(item => Number(item.quantity));
      setItemQuantities(initialQuantities);
      const uniqueCustomers = Array.from(new Set(result.map(item => item.customer_id)));
      if (result.length === 0) {

        setIsLoading(false);
      } else if (uniqueCustomers.length === 1) {
        const customerId = uniqueCustomers[0];
        setCustomer(customerId);
      } else {
        console.error('Error: Customer IDs are not the same');
        setIsLoading(false);
      }
      const delivery = Array.from(new Set(result.map(item => item.delivery_fee)));
      if (result.length === 0) {

        setIsLoading(false);
      } else if (delivery.length === 1) {
        const deliverFee = delivery[0];
        setDeliveryFee(deliverFee);
      } else {
        console.error('Error: Delivery fee are not the same');
        setIsLoading(false);
      }
      const uniqueProductIds = result.map(item => item.product_id);
      setProduct(uniqueProductIds);

      const taxx = result.map(item => parseFloat(item.product?.tax));
      const totalTaxAmount = taxx.reduce((acc, currentTax) => acc + currentTax, 0);
      setTaxAmount(totalTaxAmount);
      console.log(taxx, "HERE")

      const amounts = result.map(item => item.amount);
      setAmount(amounts);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      setCartItems([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (bearer) {
      fetchAllOrders();
    }
  }, [bearer]);

  const fetchAllStores = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://gtc.ogunstate.gov.ng/api/stores`, {
        headers,
      });
      const allStores = response?.data?.data;
      if (Array.isArray(allStores)) {
        const outletStores = allStores.filter(store => store.type === "Outlet");
        setStores(outletStores);

        if (outletStores.length > 0) {
          setSelectedStore(outletStores[0]);
          setSelectedStoreText(outletStores[0].store_address);
          setSelectedStoreId(outletStores[0].store_id);
        }
      } else {
        setStores([]);
      }
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      setStores([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (bearer) {
      fetchAllStores();
    }
  }, [bearer]);

  const Checkout = () => {
    Navigate('/CheckoutPage')
  }

  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
  };


  const handleCheckout = async () => {
    setLoad(true);
    try {
      let deliveryDetail = '';

      if (deliveryMethod === 'Door Delivery') {
        deliveryDetail = selectedAddress;
      } else if (deliveryMethod === 'Pickup Station') {
        deliveryDetail = selectedStoreId;
      }

      const itemQuantitiesAsString = itemQuantities.map(quantity => quantity.toString());

      const requestBody = {
        product_id: product,
        customer_id: customer,
        quantity: itemQuantitiesAsString,
        amount: amount,
        mode_of_delivery: deliveryMethod,
        payment_method: selectedPayment,
        note: deliveryInstruction
      };

      if (deliveryMethod === 'Door Delivery') {
        requestBody.delivery_address = deliveryDetail;
      } else if (deliveryMethod === 'Pickup Station') {
        requestBody.store_id = deliveryDetail;
      }

      const response = await axios.post(
        'https://gtc.ogunstate.gov.ng/api/make-order',
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log('Success:', response.data.message);
      fetchAllOrders();
      Swal.fire({
        imageUrl: Success,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Successful',
        title: 'Order Placed Successfully!',
        text: 'Your order has been placed successfully. Thank you for shopping with us.',
        showConfirmButton: true,
        confirmButtonText: 'Go Back to Homepage',
        confirmButtonColor: "#2F945C",
      }).then(() => {
        navigate('/onboarding');
      });
    } catch (error) {
      // Handle error response
      const errorMessage = error.response?.data?.message || 'An error occurred';
      console.log('Error:', errorMessage);

      Swal.fire({
        icon: "error",
        title: 'Oooops.....',
        text: errorMessage, // Display the error message from the API response
        icon: 'error', // You can use other icons like 'warning', 'info', etc.
        showConfirmButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#FF5733', // Customize the button color
      });

    } finally {
      setLoad(false);
    }
  }


const placeholderCount = 2;



  return (
    <div>
      {authenticated ?
        <NewnavHome />
        :
        <Newnav />
      }
      <div className='body'>

        <div className='main'>
          <div className='Cart-container'>

            <div className='mainCart'>


              <div className='my-cart'>
                <span>
                  <h3>My Cart</h3>
                  <p className='cart-text'>{cartItems.length}</p>
                </span>
              </div>
              <div className='cartdetails-container'>
              {isLoading ? (
    Array.from({ length: placeholderCount }, (_, index) => (
      
        <Col lg={5} className='lg5' key={index}>
            <Card className='text-center '>
                <div>
                    <div id='imgs' style={{ cursor: 'default' }}>
                        <Card.Img variant="top" src={Download} alt="loading..." />
                    </div>
                    <Card.Body>
                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder xs={6} />
                        </Placeholder>
                    </Card.Body>
                </div>
            </Card>
        </Col>
    ))
) : (
    <>
        {cartItems.length > 0 ? (
            // Cart items are present
            cartItems.map((cartItem, index) => (
                <div className='cart-details' key={index}>
                    <div className='cart-images'>
                        <img src={cartItem.product.image} alt={cartItem.product.name} />
                    </div>
                    <div id='content'>
                        <div id='h4'>
                            <h4 style={{ fontSize: 14 }}>{cartItem.product.name} </h4>
                            <h4 style={{ fontSize: 14, color: "#027f07" }}>
₦ {Number(itemQuantities[index] * cartItem.product.discount_price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
</h4>

                        </div>

                        <div className='Btns'>
                            <div className='main-btns'>
                            <button className='btnss' onClick={() => handleDecrement(index)}><i className='bx bx-minus'></i></button>
                                <p className='qty-text'> {itemQuantities[index]} </p>
                                <button className='btnss' onClick={() => handleIncrement(index)} id='add'><i className='bx bx-plus'></i></button>
                            </div>
                            <button className='btnss' onClick={() => handleDeleteCart(cartItem.id)}><i className='bx bx-trash'></i></button>
                        </div>
                    </div>
                </div>
            ))
        ) : (
            // Cart is empty
            <div className="empty-cart">
                <img src={Empty} alt="Empty Cart" />
                <p>No item in the cart</p>
            </div>
        )}
    </>
)}

              </div>

              {cartItems.length > 0 && (
                <div>
                  <div id="subtotal">
                    <p>Subtotal</p>
                    <h4 style={{color: "#027f07"}}>₦{calculateTotalPrice()}</h4>

                  </div>
                  {/* <button onClick={Checkout} className="checkout">Checkout</button> */}
                </div>
              )}

            </div>

            {/* <div className='Checkout'>    
                        </div> */}
            <div className='PaymentInfo'>
              <div className='pay-info-app-dis'>
                <h3>Payment Information</h3>
                <h4>Apply Discount</h4>
              </div>
              <div className='inputs'>
                <input type='text' placeholder='Enter Coupon Code' />
                <button>Apply</button>
              </div>

              <div className='payment-options'>
                <h4>Select Payment Method</h4>
                <div className='payment-option'>
                  <input
                    type='radio'
                    id='bpms'
                    name='paymentMethod'
                    value='bpms'
                    checked={selectedPayment === 'bpms'}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor='Ogun State BPMS'>Ogun State BPMS</label>
                </div>
                <div className='payment-option'>
                  <input
                    type='radio'
                    id='pay later'
                    name='paymentMethod'
                    value='pay later'
                    checked={selectedPayment === 'pay later'}
                    onChange={handleOptionChange}
                  />
                  <label htmlFor='Pay Later'>Pay Later</label>
                </div>
              </div>


              <div className='payment-options'>
                <h4>Select Delivery Method</h4>
                <div className='payment-option'>
                  <input
                    type='radio'
                    id='Door Delivery'
                    name='deliveryMethod'
                    value='Door Delivery'
                    checked={deliveryMethod === 'Door Delivery'}
                    onChange={handleDelivery}
                  />
                  <label htmlFor='Door Delivery'>Door Delivery</label>
                </div>

                {deliveryMethod === 'Door Delivery' && (
  <div>
    <DropdownButton
      id="dropdown-item-button"
      title={
        selectedAddress.length > maxLength
          ? `${selectedAddress.substring(0, maxLength)}...`
          : selectedAddress || 'Select your Address'
      }
    >
      {loading ? (
        <Dropdown.ItemText>Loading...</Dropdown.ItemText>
      ) : (
        addresses
          .filter((address) => address)
          .map((address, index) => (
            <Dropdown.Item
              as="button"
              key={index}
              onClick={() => handleAddressSelect(address)}
            >
              {address.length > maxLength
                ? `${address.substring(0, maxLength)}...`
                : address}
            </Dropdown.Item>
          ))
      )}
    </DropdownButton>

    <div>
      <label>Delivery Instructions:</label>
      <textarea
        rows="4"
        cols="50"
        value={deliveryInstruction}
        onChange={(e) => setDeliveryInstruction(e.target.value)}
      ></textarea>
    </div>
  </div>
)}


              <div className='payment-option'>
                <input
                  type='radio'
                  id='Pickup Station'
                  name='deliveryMethod'
                  value='Pickup Station'
                  checked={deliveryMethod === 'Pickup Station'}
                  onChange={handleDelivery}
                />
                <label htmlFor='Pickup Station'>Pickup Station</label>
              </div>

              {deliveryMethod === 'Pickup Station' && (
                <DropdownButton id="dropdown-item-button" title={selectedStoreText.length > maxLength ? `${selectedStoreText.substring(0, maxLength)}...` : selectedStoreText || 'Select your desired Store'}>
                  {loading ? (
                    <Dropdown.ItemText>Loading...</Dropdown.ItemText>
                  ) : (
                    stores
                      .filter((store) => store)
                      .map((store, index) => (
                        <Dropdown.Item
                          as="button"
                          key={index}
                          onClick={() => handleStoreSelect(store)}
                        >
                          {store.store_address.length > maxLength ? `${store.store_address.substring(0, maxLength)}...` : store.store_address}
                        </Dropdown.Item>
                      ))
                  )}
                </DropdownButton>
              )}
              </div>

              <div className='paymentDetails'>
                <div>
                  <p>Sub Total</p>
                  <p>₦{calculateTotalPrice()}</p>
                </div>
                <div>
                  <p>V.A.T</p>
                  <p>₦ {calculateTotalTax()}</p>
                </div>
                <div>
                  <p>Delivery Fee </p>
                  <p >₦{Number(deliveryFee).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </div>
                {/* <div>
                <p>Shipping</p>
                <p>$0.00</p>
              </div> */}
              </div>
              <div className='total'>
                <p>Total</p>
                <span>₦{calculateFinalTotal()}</span>
              </div>
              {cartItems.length > 0 && (
              <button onClick={handleCheckout} id="pay">
                {load ? <ActivityIndicator /> : `Proceed to pay ₦${calculateFinalTotal()}`}
              </button>
              )}
            </div>
          </div>
        </div>

      </div>
      <Newfooter />
    </div>
  );
}