import React, { useState, useEffect } from 'react';
import NewnavHome from '../Components/NewnavHome';
import Newnav from '../Components/Newnav';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Newfooter from '../Components/Newfooter';

export const FAQ = () => {
    const [selected1, setSelected1] = useState(null);
    const [selected2, setSelected2] = useState(null);
    const [bearer, setBearer] = useState('');
    const [authenticated, setAuthenticated] = useState(false);
    const readData = async () => {
      try {
          const value2 = await AsyncStorage.getItem('userToken');

          if (value2 !== null) {
              // console.log(value2);
              setBearer(value2);
              setAuthenticated(true);
          }
      } catch (e) {
          alert('Failed to fetch the input from storage');
      }
  };
  useEffect(() => {
      readData();
  }, []);

    const toggle1 = (i) => {
        if (selected1 === i) {
            setSelected1(null);
        } else {
            setSelected1(i);
        }
    };

    const toggle2 = (i) => {
        if (selected2 === i) {
            setSelected2(null);
        } else {
            setSelected2(i);
        }
    };

    return (
        <div>
           {authenticated ? 
           <NewnavHome /> 
            :
            <Newnav />
}
            <div className='body'>
                <div className='main'>
                    <div className='FAQ'>
                        <div className='faq-cont'>
                            <h1>Frequently Asked Questions</h1>
                            <div className='questions-cont'>
                                <div className='accordion'>
                                    {data1.map((items, i) => (
                                        <div className='items' onClick={() => toggle1(i)}>
                                            <div className='accordion-title'>
                                                <h4>{items.question}</h4>
                                                <span>{selected1 === i ? <i className='bx bx-minus-circle'></i> : <i className='bx bx-plus-circle'></i>}</span>
                                            </div>
                                            <div className={selected1 === i ? 'content.show' : 'content'}>
                                                {items.answer}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className='accordion'>
                                    {data2.map((items, i) => (
                                        <div className='items' onClick={() => toggle2(i)}>
                                            <div className='accordion-title'>
                                                <h4>{items.question}</h4>
                                                <span>{selected2 === i ? <i className='bx bx-minus-circle'></i> : <i className='bx bx-plus-circle'></i>}</span>
                                            </div>
                                            <div className={selected2 === i ? 'content.show' : 'content'}>
                                                {items.answer}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               
            </div>
            <Newfooter />
        </div>
    );
};

const data1 = [
    {
        question: 'Question 1',
        answer: 'Gateway Trading Company has secured an office at the Tech Hub building located at Kobape, Abeokuta-Sagamu expressway to support its administrative operations.',
    },
    {
        question: 'Question 2',
        answer: 'Gateway Trading Company has secured an office at the Tech Hub building located at Kobape, Abeokuta-Sagamu expressway to support its administrative operations.',
    },
];

const data2 = [
    {
        question: 'Question 3',
        answer: 'Gateway Trading Company has secured an office at the Tech Hub building located at Kobape, Abeokuta-Sagamu expressway to support its administrative operations.',
    },
    {
        question: 'Question 4',
        answer: 'Gateway Trading Company has secured an office at the Tech Hub building located at Kobape, Abeokuta-Sagamu expressway to support its administrative operations.',
    },
];
