import React from 'react';

function Activity() {
  return (
    <div className="activity-indicator1">
      <div className="spinn"></div>
    </div>
  );
}

export default Activity;