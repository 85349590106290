import React from 'react';
import OnbImg from '../Images/pwd-crte-img.png'
import { GoBackHome } from '../Components/GoBackHome';

export const ChangePassWord = () => {
  return (
    <div className='signin background'>
        <div className='marketersImg'>
            <img src={OnbImg} className="leftonb-img" alt="img" />
        </div>
        <div className='cpc'>
                <div className='cpc-go-back'>
                    <GoBackHome/>
                </div>
            <div className='changepassword-container'>
                <h1>Change password</h1>
                <div id='ctn'>
                    <div id='changePasswoer'>
                        <input type="text" className="sn-input" placeholder="enter old password" />    
                        <input type="text" className="sn-input" placeholder="enter new password" />
                        <input type="text" className="sn-input" placeholder="confirm new password" />
                        <button className='submit sn-input'>change password</button>
                    </div>               
                </div>
            </div>
        </div>
    </div>
  )
}