import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import Newfooter from '../Components/Newfooter';
import NewnavHome from './NewnavHome';
import avatar from '../Emojis/Avatarp.png';
import Lomap from '../Emojis/map.png';
import ReactToPrint from 'react-to-print';
import OrderDetailsPrintable from './OrderDetailsPrintable';
import Editfld from '../Emojis/pencil-edit.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import ActivityIndicator from './ActivityIndicator';
import { Placeholder } from 'react-bootstrap';
import { MDBDataTable } from 'mdbreact';
import Newnav from './Newnav';
import {Trash } from 'react-bootstrap-icons';






export const MyProfile = () => {
    const [selectedState, setSelectedState] = useState('');
    const [selectedLocalGovt, setSelectedLocalGovt] = useState('');
    const [show, setShow] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [orderShow, setOrderShow] = useState(false);
    const [orderClose, setOrderClose] = useState(false);
    const [passwordClose, setPasswordClose] = useState(false);
    const [addressClose, setAddressClose] = useState(false);
    const [addressShow, setAddressShow] = useState(false);
    const [address1Close, setAddress1Close] = useState(false);
    const [address1Show, setAddress1Show] = useState(false);
    const [address2Close, setAddress2Close] = useState(false);
    const [address2Show, setAddress2Show] = useState(false);
    const [password_confirmation, setPassword_confirmation] = useState('');
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleOrderClose = () => setOrderShow(false);
    const handleShow = () => setShow(true);
    const handlePasswordShow = () => setPasswordShow(true);
    const handlePasswordClose = () => setPasswordShow(false);
    const handleAddressClose = () => setAddressShow(false);
    const handleAddressShow = () => setAddressShow(true);
    const handleAddress1Close = () => setAddress1Show(false);
    const handleAddress1Show = () => setAddress1Show(true);
    const handleAddress2Close = () => setAddress2Show(false);
    const handleAddress2Show = () => setAddress2Show(true);
    const [email, setEmail] = useState('');
    const [memberDetails, setMemberDetails] = useState([]);
    const [bearer, setBearer] = useState('');
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isPasswordLoading, setIsPasswordLoading] = useState(false);
    const [isLogoutLoading, setIsLogoutLoading] = useState(false);
    const [firstname, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [ogNumber, setOgNumber] = useState("");
    const [address, setAddress] = useState("");
    const [stateOfOrigins, setStateOfOrigins] = useState([]);
    const [localGovernments, setLocalGovernments] = useState([]);
    const [isStateLoading, setIsStateLoading] = useState(false);
    const [isLocalGovtLoading, setIsLocalGovtLoading] = useState(false);
    const [address1, setAddress1] = useState("");
    const [address2, setAddress2] = useState("");
    const [addressUpdated, setAddressUpdated] = useState(false);
    const [loadingg, setLoadingg] = useState(false);
    const [load, setLoad] = useState(false);
    const [loadinggs, setLoadinggs] = useState(false);
    const [paid, setPaid] = useState([]);
    const [wishlist, setWishlist] = useState([]);
    const [authenticated, setAuthenticated] = useState(false);
    const location = useLocation();
    const [modalShow, setModalShow] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [loadingItemId, setLoadingItemId] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [showItemAdded, setShowItemAdded] = useState(false);
    const [addToCartPressed, setAddToCartPressed] = useState(false);
    const [rating, setRating] = useState(0);
    const [review, setReview] = useState('');
    const [showRatingModal, setShowRatingModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedOrderProducts, setSelectedOrderProducts] = useState([]);

console.log(selectedOrder);

    const handleStarClick = (star) => {
        setRating(star);
      };
    
      const handleReviewChange = (event) => {
        setReview(event.target.value);
      };

      const handleRowClick = (order) => {
        setSelectedOrder(order);
        setModalShow(true);
    };

      const handleRowClick1 = (order) => {
        setSelectedOrder(order);
        setSelectedOrderProducts(order.order);
        setShowRatingModal(true);
    };

    const fetchPaid = async () => {
        setLoadinggs(true);
        try {
            const response = await axios.get('https://gtc.ogunstate.gov.ng/api/paid-pending-orders', { headers });
            const results = response.data?.data;
            setPaid(results.reverse());
          
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setPaid([]);
        } finally {
            setLoadinggs(false);
        }
    };

    useEffect(() => {
        if (bearer) {
            fetchPaid();
        }
    }, [bearer]);

    const fetchWishlist = async () => {
        setLoadinggs(true);
        try {
            const response = await axios.get('https://gtc.ogunstate.gov.ng/api/customer-wish-list', { headers });
            const resultsS = response.data?.data;
            setWishlist(resultsS.reverse());
          
        } catch (error) {
            const errorStatus = error.response?.data?.message;
            console.log(errorStatus);
            setWishlist([]);
        } finally {
            setLoadinggs(false);
        }
    };

    useEffect(() => {
        if (bearer) {
            fetchPaid();
            fetchWishlist();
        }
    }, [bearer]);

    const toggleRowSelection = (index) => {
        if (selectedRows.includes(index)) {
            setSelectedRows(selectedRows.filter((rowIndex) => rowIndex !== index));
        } else {
            setSelectedRows([...selectedRows, index]);
        }
    };

    const OrderDetailsModal = ({ show, onHide, order }) => {
        const orderDetailsPrintableRef = React.createRef();

        const calculateVAT = () => {
            let totalTax = 0;
        
            if (order && order.order) {
              order.order.forEach((item) => {
                totalTax += parseFloat(item.tax_amount || 0);
              });
            }
        
            return totalTax.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          };

         
          const calculateTotalAmount = () => {
            const totalVAT = Number(calculateVAT().replace(/,/g, ''));
            const totalOrderAmount = Number(order.total_amount);
          
            const finalTotal = totalVAT + totalOrderAmount;
          
            const formattedFinalTotal = finalTotal.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            });
          
            return formattedFinalTotal;
          };
          
          
        
 
        return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                {/* <Modal.Title>{order.reference_no}</Modal.Title> */}

            </Modal.Header>
            <Modal.Body ref={orderDetailsPrintableRef}>
                {order && (
                    <div>
                        <p className='ordr-dtls'>Order Details</p>
                        <ul>
                            {order.order.map((item, index) => (
                                <li key={index}>
                                    <div className='order-group'>
                                    <p className='ordr-itms'>{item.quantity}x {item.product.name}</p>
                                    <p className='ordr-itms'>₦{Number(item.amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className='dlvry-fee'>
                            <p>Subtotal: </p>
                            <p className='dlvry-fee1'>₦{Number(order.total_amount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        </div>
                        <div className='dlvry-fee'>
                            <p>Delivery Fee: </p>
                            <p className='dlvry-fee1'>₦{Number(order.delivery_fee).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                        </div>
                        <div className='order-totl2'>
                            <p >V.A.T:  </p><p id='ordr-totl'> ₦ {calculateVAT()}</p></div>
                        <div className='order-totl2'>
                            <p >Total Amount paid:  </p><p id='ordr-totl'> ₦ {calculateTotalAmount()}</p></div>

                        <div className='pmnt-sts'>  <p>Payment Status: </p><p className='stats-pmt'>{order.payment_status}</p></div>
                        <div className='dlvry-status'>      <p >Delivery Status: </p><p style={{
                            color: order.status === "Pending" ? "#04326B" : "#099137",
                            backgroundColor: order.status === "Pending" ? "#E3EFFC" : '#E7F6EC', fontSize: '12px', fontWeight: '600',
                            padding: "3px 15px",
                            borderRadius: "10px"
                        }}> {order.status}</p></div>
                        <div className='dlvry-mode'>
                            <p>Mode of Delivery:</p> <p className='mode-dlvry'>{order.mode_of_delivery}</p></div>
                        {order.mode_of_delivery === 'Door Delivery' ? (
                            <div ><p>Delivery Address:</p> <p className='dlvry-ad'>{order.delivery_address}</p></div>
                        ) : order.mode_of_delivery === 'Pickup Station' ? (
                            <div><p>Store Address:</p>
                                <p className='str-ad'> {order.store.store_address}</p></div>
                        ) : null}





                    </div>
                )}
            </Modal.Body>
            <Modal.Footer>
            <ReactToPrint
          trigger={() => <Button>Print</Button>}
          content={() => orderDetailsPrintableRef.current}
        />
                <Button id='order-button' onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
            
        </Modal>
    );
};

const renderRatingModal = () => {
    return (
      <Modal show={showRatingModal} onHide={() => setShowRatingModal(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div>
            <p className='ordr-dtls'>Rate & Review</p>
            <Form.Label htmlFor="SelectItem">Select Item</Form.Label>
            <Form.Select
                style={{ marginBottom: 20 }}
                aria-label="Order select"
                onChange={(e) => setSelectedItem(e.target.value)}
            >
                <option>Select an item</option>
                {selectedOrderProducts.map((item) => (
                    <option key={item.product?.id} value={item.product?.id}>
                        {item.product?.name || 'Unnamed Product'}
                    </option>
                ))}
            </Form.Select>
            <Form.Label htmlFor="SelectStars">Rate product</Form.Label> <br />
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                onClick={() => handleStarClick(star)}
                style={{
                  cursor: 'pointer',
                  color: star <= rating ? 'gold' : 'gray',
                  fontSize: '1.5em',
                  marginBottom: 20
                }}
              >
                &#9733;
              </span>
            ))}
          </div>
          <Form.Label htmlFor="SelectStars">Review product</Form.Label>
          <textarea
            value={review}
            onChange={handleReviewChange}
            placeholder="Write your review..."
            style={{ marginTop: '10px', width: '100%', marginBottom: 20 }}
          />
          <Button id='send-review' onClick={() => handleSendReview()} style={{ marginTop: '10px' }}>
            {loadingItemId ? "Saving your review..." : "Send Review"}
          </Button>
        </Modal.Body>
      </Modal>
    );
  };
  
    const renderTable = () => {
        if (loadinggs) {
            return <p>Fetching orders....</p>;
        } else if (paid.length === 0) {
            return <p>No Orders</p>;
        } else {
            const data = {
                columns: [
                    {
                        label: 'Order No',
                        field: 'order_no',
                        sort: 'asc',
                        width: 150,
                    },
                    {
                        label: 'Date',
                        field: 'date',
                        sort: 'asc',
                        width: 270,
                    },
                    {
                        label: 'Mode of Delivery',
                        field: 'mode_of_delivery',
                        sort: 'asc',
                        width: 200,
                    },
                    {
                        label: 'Item Qty',
                        field: 'item_qty',
                        sort: 'asc',
                        width: 100,
                    },
                    {
                        label: 'Payment Status',
                        field: 'payment_status',
                        sort: 'asc',
                        width: 150,
                    },
                    {
                        label: 'Delivery Status',
                        field: 'delivery_status',
                        sort: 'asc',
                        width: 100,
                    },

                   {label: 'Action', field: 'action', sort: 'asc', width: 150 },
                ],
                rows: paid.map((item, index) => {
                    const productCreationDates = item.order.map((orderItem) => {
                        const product = orderItem;
                        if (product && product.created_at) {
                            return new Date(product.created_at).toLocaleString();
                        }
                        return 'N/A'; // Handle missing or invalid dates
                    });

                    return {
                        order_no: (
                            <span
                                style={{ cursor: 'pointer', color: "#027f07" }}
                                onClick={() => handleRowClick(item)}
                            >
                                {item.reference_no}
                            </span>
                        ),
                        date: productCreationDates[0] || 'N/A', // Pick the first date or use 'N/A' if the array is empty
                        mode_of_delivery: item.mode_of_delivery,
                        item_qty: `${item.order.length} qty`,
                        payment_status: (
                            <span
                                style={{
                                    color: item.payment_status === 'Paid' ? "#027f07" : '#D42620',
                                }}
                            >
                                {item.payment_status}
                            </span>
                        ),
                        delivery_status: (
                            <span
                                style={{
                                    color: item.status === 'Pending' ? '#04326B' : "#027f07",
                                }}
                            >
                                {item.status}
                            </span>
                        ),
                        action: (
                            <div>
                              <div
           onClick={() => handleRowClick1(item)}
          style={{
            backgroundColor: 'green',
            display: 'inline-block',
            padding: '10px',
            border: '1px solid rgba(40, 167, 69, .3)',
            cursor: 'pointer',
            transition: 'background-color 0.10s ease',
            borderRadius: 5,
            color: '#fff',
          }}
        >
          Rate & Review
        </div>
        {renderRatingModal()}
                              </div>
                              )
                    };
                }),
            };

            return (
                <div>
                    <MDBDataTable
                        responsiveSm={true}
                        striped
                        bordered
                        small
                        data={data}
                        noBottomColumns
                    />
                    <OrderDetailsModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        order={selectedOrder}
                    />
                </div>
            );
        }
    };


    const handleSendReview = async () => {
        // Set loading
        setLoadingItemId(true);
      
        try {
         
          const response = await axios.post(
            'https://gtc.ogunstate.gov.ng/api/review-product',
            {
                product_id: selectedItem,
              reference_no: selectedOrder.reference_no,
              review: review,
              rating: rating,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${bearer}`,
              },
            }
          );
      
          console.log(response.data.message);
      
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            confirmButtonText: 'Proceed',
            confirmButtonColor: '#027f07',
          });

          setRating('0');
          setReview('');
          setShowRatingModal(false);
        } catch (error) {
          // Handle error response
          const errorMessage =
            JSON.stringify(error.response?.data?.message) || 'An error occurred';
      
          Swal.fire({
            icon: 'error',
            title: 'Failed',
            text: error.response?.data?.message,
            confirmButtonText: 'Go back',
            confirmButtonColor: 'red',
          });
      
          if (error.response && error.response.status === 401) {
            navigate('/sign_in');
          }
      
          console.error('Error:', error.response?.data?.message);
        } finally {
          // Reset loading state
          setLoadingItemId(false);
        }
      };
      


    const handleAddToCart = async (id) => {
        // Set loading and selected item ID
        setLoadingItemId(id);
        setSelectedItemId(id);


        try {
            // Make an API request to add the item to the cart
            const response = await axios.post(
                'https://gtc.ogunstate.gov.ng/api/add-to-cart-from-wish-list',
                {
                    id: id,
                    quantity: 1,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${bearer}`,
                    }
                }
            );
            setWishlist(prevWishList => prevWishList.filter(item => item.id !== id));
console.log(response.data.message)
            // Update UI state
            setAddToCartPressed(true);
            setButtonDisabled(true);

            // Show a confirmation message and reset loading state
            setShowItemAdded(true);
            setTimeout(() => {
                setShowItemAdded(false);
                setLoadingItemId(null);
            }, 2000);
        } catch (error) {
            // Handle error response
            const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
            if (error.response && error.response.status === 401) {

                navigate('/sign_in');
            }
            console.log('Error:', errorMessage);
            setLoadingItemId(null);
        }


    };

    const handleDeleteWishlist = async (id) => {
        try {
          const response = await axios.get(
            `https://gtc.ogunstate.gov.ng/api/delete-customer-wish-list?id=${id}`,
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearer}`
              },
            }
          );
          console.log('Success:', response.data.message);
          setWishlist(prevWishList => prevWishList.filter(item => item.id !== id));
        } catch (error) {
          const errorMessage = JSON.stringify(error.response?.data?.message || 'An error occurred');
          console.log('Error:', errorMessage);
          //   Alert.alert('Failed', errorMessage);
        }
      };

    const renderWishlist = () => {
  if (loadinggs) {
    return <p>Fetching Wishlist....</p>;
  } else if (wishlist.length === 0) {
    return <p>No wishlist</p>;
  } else {
    return (
      <div>
        <MDBDataTable
          responsiveSm={true}
          striped
          bordered
          small
          data={{
            columns: [
              { label: 'Product Image', field: 'image', sort: 'asc', width: 150 },
              { label: 'Product Name', field: 'prod_name', sort: 'asc', width: 150 },
              { label: 'Unit Price', field: 'unit_price', sort: 'asc', width: 270 },
              { label: 'Action', field: 'action', sort: 'asc', width: 150 },
            ],
            rows: wishlist.map((item, index) => {
              const formattedPrice =
                item.product?.discount_price?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) || 'N/A'; // Provide a default value if discount_price is not available

              return {
                image: (
                  <img
                    src={item.product?.image}
                    alt="Item"
                    style={{
                      height: '50px',
                      width: '50px',
                      borderRadius: '10px',
                      border: '2px solid #ccc', // Gray border
                    }}
                  />
                ),
                prod_name: item.product?.name || 'N/A', // Provide a default value if name is not available
                unit_price: `₦${formattedPrice}`,
                action: (
                  <div>
                    <div
                      onClick={() => handleAddToCart(item.id)}
                      style={{
                        backgroundColor: 'green',
                        display: 'inline-block',
                        padding: '10px',
                        border: '1px solid rgba(40, 167, 69, .3)',
                        cursor: 'pointer',
                        transition: 'background-color 0.10s ease',
                        borderRadius: 5,
                        color: '#fff',
                      }}
                    >
                      Add to Cart
                    </div>
                    <div
                    onClick={() => handleDeleteWishlist(item.id)}
                      style={{
                        backgroundColor: 'rgba(220, 53, 69, .1)',
                        transition: 'background-color 0.10s ease',
                        display: 'inline-block',
                        padding: '10px',
                        marginLeft: '10px',
                        border: '1px solid rgba(220, 53, 69, .3)',
                        cursor: 'pointer',
                      }}
                    >
                      <Trash style={{ color: '#dc3545' }} />
                    </div>
                  </div>
                ),
              };
            }),
          }}
          noBottomColumns
        />
        <OrderDetailsModal show={modalShow} onHide={() => setModalShow(false)} order={selectedOrder} />
      </div>
    );
  }
};

      
    
    




    const handleCreatePassword = async () => {
        setIsPasswordLoading(true);
    
        try {
            if (password_confirmation !== password) {
                throw new Error('Passwords do not match. Please try again.');
            }
    
            if (password === oldPassword) {
                throw new Error('New password cannot be the same as the old password.');
            }
    
            const response = await axios.post(
                'https://gtc.ogunstate.gov.ng/api/change-password',
                {
                    old_password: oldPassword,
                    new_password: password,
                    password_confirmation: password_confirmation,
                },
                { headers: { Authorization: `Bearer ${bearer}` } }
            );
    
            const newPassword = response?.data?.message;
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: newPassword,
                confirmButtonText: 'Ok',
                confirmButtonColor: '#027f07',
            });
            navigate('/sign_in', { state: { from: location.pathname } });
            setPassword_confirmation('');
            setPassword('');
            setOldPassword('');
        } catch (error) {
            let errorMessage = 'An error occurred';
    
           
            if (error.message.includes('Passwords do not match')) {
                errorMessage = 'Passwords do not match. Please try again.';
            } else if (error.message.includes('New password cannot be the same as the old password')) {
                errorMessage = 'New password cannot be the same as the old password.';
            } else if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
    
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: errorMessage,
                confirmButtonText: 'Ok',
                confirmButtonColor: 'red',
            });
            console.log(errorMessage);
        } finally {
            setIsPasswordLoading(false);
        }
    };
    
    


    const readData = async () => {
        try {
            const value = await AsyncStorage.getItem('userToken');
            // 

            if (value !== null) {

                setBearer(value);
                setAuthenticated(true);
            }
        } catch (e) {
            alert('Failed to fetch the input from storage');
        }
    };
    useEffect(() => {
        readData();

    }, []);

    useEffect(() => {
        if (bearer) {
            fetchMemberDetails();
        }
    }, [bearer]);


    const fetchMemberDetails = async () => {
        setIsLoading(true)
        try {
            const response = await axios.get(`https://gtc.ogunstate.gov.ng/api/profile`, { headers });
            const details = response?.data?.data;
            // console.log(details)
            setMemberDetails(details);
            setFirstName(details.firstname);
            setLastName(details.lastname);
            setPhone(details.phone_number);
            setOgNumber(details.og_number);
            setAddress(details.address);
            setEmail(details.email);
            setSelectedState(details?.state_of_origin || '');
            setSelectedLocalGovt(details?.lga ?? '');
            setAddress1(details.address_1);
            setAddress2(details.address_2);
        } catch (error) {
            if (error.response.status === 401) {

                navigate('/sign_in', { state: { from: location.pathname } });
            }
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };



    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${bearer}`
    };


    useEffect(() => {
        setIsStateLoading(true);

        const fetchState = async () => {
            try {
                const response = await axios.get('https://gtc.ogunstate.gov.ng/api/nig-state');
                const responseData = response.data?.data;
                setStateOfOrigins(responseData);
            } catch (error) {
                console.error(error);
            }

            setIsStateLoading(false);
        };

        fetchState();
    }, []);



    useEffect(() => {


        if (selectedState) {
            setIsLocalGovtLoading(true);

            const fetchLocalGovt = async () => {
                try {

                    const response = await axios.get(`https://gtc.ogunstate.gov.ng/api/local-govt?state_id=${selectedState}`);
                    const responseData1 = response.data?.data;
                    setLocalGovernments(responseData1);
                    // console.log('Local governments for the selected state:', responseData1);
                } catch (error) {
                    console.error(error);
                }

                setIsLocalGovtLoading(false);
            };

            fetchLocalGovt();
        }
    }, [selectedState]);


    const handleSave = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                'https://gtc.ogunstate.gov.ng/api/update-profile',
                {
                    firstname: firstname,
                    lastname: lastName,
                    address: address,
                    phone_number: phone,
                    og_number: ogNumber,
                    email: email,
                    state_of_origin: selectedState,
                    lga: selectedLocalGovt
                },
                { headers }
            );
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
                confirmButtonText: "Proceed",
                confirmButtonColor: "#027f07"
            });

            handleClose();
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: error.response.data.message,
            });
            console.error('Error saving address:', error);
        }
        setLoading(false);
    };

    const handleNewAddress = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                'https://gtc.ogunstate.gov.ng/api/update-profile',
                {
                    address: address,
                },
                { headers }
            );
            console.log('Address saved successfully');
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
                confirmButtonText: "Proceed",
                confirmButtonColor: "#027f07"
            });
            setAddressUpdated(true);
            handleAddressClose();


        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: error.response.data.message,
                confirmButtonText: "Continue",
                confirmButtonColor: "#027f07"
            });
            console.error('Error saving address:', error);
        }
        setLoading(false);

    };



    const handleNewAddress1 = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                'https://gtc.ogunstate.gov.ng/api/update-profile',
                {
                    address_1: address1,
                },
                { headers }
            );
            console.log('Address saved successfully');
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
                confirmButtonText: "Proceed",
                confirmButtonColor: "#027f07"
            });
            setAddressUpdated(true);
            handleAddress1Close();

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: error.response.data.message,
                confirmButtonText: "Continue",
                confirmButtonColor: "#027f07"
            });
            console.error('Error saving address:', error);
        }
        setLoading(false);
    };

    const handleNewAddress2 = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                'https://gtc.ogunstate.gov.ng/api/update-profile',
                {
                    address_2: address2,
                },
                { headers }
            );
            console.log('Address saved successfully');
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
                confirmButtonText: "Proceed",
                confirmButtonColor: "#027f07"
            });
            setAddressUpdated(true);
            handleAddress2Close();

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: error.response.data.message,
                confirmButtonText: "Continue",
                confirmButtonColor: "#027f07"
            });
            console.error('Error saving address:', error);
        }
        setLoading(false);
    };

    const handleSetasDefault1 = async () => {
        setLoadingg(true);
        try {
            const response = await axios.post(
                'https://gtc.ogunstate.gov.ng/api/change-default-address',
                {
                    address: address1,
                },
                { headers }
            );
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
                confirmButtonText: "Proceed",
                confirmButtonColor: "#027f07"
            });
            console.log('Address saved successfully');

            fetchMemberDetails();

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: error.response.data.message,
                confirmButtonText: "Continue",
                confirmButtonColor: "#027f07"
            });
            console.error('Error saving address:', error);
        }
        setLoadingg(false);
    };

    const handleSetasDefault2 = async () => {
        setLoad(true);
        try {
            const response = await axios.post(
                'https://gtc.ogunstate.gov.ng/api/change-default-address',
                {
                    address: address2,
                },
                { headers }
            );
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: response.data.message,
                confirmButtonText: "Proceed",
                confirmButtonColor: "#027f07"
            });

            fetchMemberDetails();

        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: error.response.data.message,
                confirmButtonText: "Continue",
                confirmButtonColor: "#027f07"
            });
            console.error('Error saving address:', error);
        }
        setLoad(false);
    };

    const handleLogout = async () => {
        setIsLogoutLoading(true);

        try {
            // Log AsyncStorage contents before clearing
            const beforeClear = await AsyncStorage.getAllKeys();
            console.log('AsyncStorage content before clear:', beforeClear);

            const response = await axios.post('https://gtc.ogunstate.gov.ng/api/logout', {}, { headers });
            console.log(response?.data?.message);
            // Clear AsyncStorage
            await AsyncStorage.clear();
            console.log('AsyncStorage cleared.');

            // Log AsyncStorage contents after clearing
            const afterClear = await AsyncStorage.getAllKeys();
            console.log('AsyncStorage content after clear:', afterClear);

            navigate("/");
        } catch (error) {
            if (error.response.status === 401) {
                navigate('/sign_in');
            }
            console.error(error);
        } finally {
            setIsLogoutLoading(false);
        }
    };

    return (
        <div>
            {authenticated ?
                <NewnavHome />
                :
                <Newnav />
            }
            {/* <div className='body'> */}
            {/* <div className='main'> */}
            <div className='details-section'>
                <div className='main'>
                    <div className='details-section-1st-div'>

                        {isLoading ? (
                            <div>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                            </div>
                        ) : (
                            <div>
                                <img src={avatar} alt='User' style={{ width: '58px', }} className='profileavat' />
                                <h2>{firstname + (" ") + lastName}</h2>
                                <p>{email.toLowerCase()}</p>
                            </div>
                        )}
                    </div>

                    <div className='details-contact'>
                        <div className='det-add det-add-address'>
                            {isLoading ? (
                                <div>
                                    <Placeholder as="div" animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                </div>
                            ) : (
                                <div>
                                    <h6 className='det-head det-add-address' style={{ color: '#475467', }} >Address</h6>
                                    <h6 className='det-head det-add-address' >{address} </h6>
                                </div>
                            )}
                        </div>
                        <div className='det-add details-contact-2nd-div'>
                            {isLoading ? (
                                <div>
                                    <Placeholder as="div" animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                </div>
                            ) : (
                                <div>
                                    <h6 className='det-info' style={{ color: '#475467', }}>Phone Number</h6>
                                    <h6 className='det-info' style={{ paddingTop: '8px' }}>{phone}</h6>
                                </div>
                            )}
                        </div>

                        <div className='det-add details-contact-2nd-div'>

                            {isLoading ? (
                                <div>
                                    <Placeholder as="div" animation="glow">
                                        <Placeholder xs={6} />
                                    </Placeholder>
                                </div>
                            ) : (
                                <div>
                                    <h6 className='det-info' style={{ color: '#475467', }}>OG Number</h6>
                                    <h6 className='det-info' style={{ paddingTop: '8px' }}>{ogNumber}</h6>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='edit-profile'>
                        {isLoading ? (
                            <div>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                            </div>
                        ) : (
                            <div>
                                <Button id='edit-profile-button' variant="success" onClick={handleShow}>Edit profile</Button>
                            </div>
                        )}

                        {isLoading ? (
                            <div>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                            </div>
                        ) : (
                            <div>
                                <Button id='change-password' variant="primary" onClick={handlePasswordShow}>Change Password</Button>

                                <Modal show={passwordShow} onHide={handlePasswordClose}>
                                    <Modal.Header closeButton>

                                        <Image src={avatar} style={{ height: "50px", width: "50px" }} rounded />
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form><Row>
                                            <Col xs={12} md={12}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Old Password</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Old Password"
                                                    autoFocus
                                                    value={oldPassword}
                                                    onChange={(e) => setOldPassword(e.target.value)}
                                                />
                                            </Form.Group></Col>
                                        </Row></Form>
                                        <Form><Row>
                                            <Col xs={12} md={12}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter New Password"
                                                    autoFocus
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </Form.Group></Col>
                                        </Row></Form>
                                        <Form><Row>
                                            <Col xs={12} md={12}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Confirm Password</Form.Label>
                                                <Form.Control
                                                    type="address"
                                                    placeholder="Confirm your Password"
                                                    autoFocus
                                                    value={password_confirmation}
                                                    onChange={(e) => setPassword_confirmation(e.target.value)}
                                                />
                                            </Form.Group></Col>
                                        </Row></Form>
                                    </Modal.Body>
                                    <Modal.Footer>

                                        <Button id='password-btn' variant="success" onClick={handleCreatePassword} >{isPasswordLoading ? <ActivityIndicator /> : 'Save Changes'}</Button>

                                    </Modal.Footer>
                                </Modal>
                            </div>
                        )}

                        {isLoading ? (
                            <div>
                                <Placeholder as="div" animation="glow">
                                    <Placeholder xs={6} />
                                </Placeholder>
                            </div>
                        ) : (
                            <div>
                                <Button id='logout-btn' variant="danger" onClick={handleLogout}>{isLogoutLoading ? "Signing Out..." : "Log out"}</Button>
                            </div>
                        )}

                    </div>

                    <div>

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                {/* <Modal.Title>Edit Profile</Modal.Title> */}
                                <Image src={avatar} style={{ height: "50px", width: "50px" }} rounded />
                            </Modal.Header>
                            <Modal.Body>
                                <Form><Row>
                                    <Col xs={6} md={6}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>First Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter your First Name"
                                            autoFocus
                                            value={firstname}
                                            readOnly={true}
                                            className="grayed-out-input"
                                            
                                        />
                                    </Form.Group></Col>
                                    <Col xs={6} md={6}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Last Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter your Last Name"
                                            autoFocus
                                            value={lastName}
                                            readOnly={true}
                                            className="grayed-out-input"
                                        />
                                    </Form.Group></Col>
                                    <Col xs={6} md={6}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter phone number"
                                            autoFocus
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </Form.Group></Col>
                                    <Col xs={6} md={6}><Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>OG number</Form.Label>
                                        <Form.Control type="alphanumeeric"
                                            placeholder="Enter OG number"
                                            autoFocus
                                            value={ogNumber}
                                            readOnly={true}
                                            className="grayed-out-input"
                                        />
                                    </Form.Group></Col>
                                    <Col xs={6} md={6}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control
                                            type="address"
                                            placeholder="Enter Address"
                                            autoFocus
                                            value={address}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                    </Form.Group></Col>
                                    <Col xs={6} md={6}><Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control type="email"
                                            placeholder="Enter Email Address"
                                            autoFocus
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Form.Group></Col>
                                    <Col xs={6} md={6}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>State of Origin</Form.Label>
                                            <Form.Control as="select" value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                                                <option value="">Select state of origin</option>
                                                {stateOfOrigins.map((state) => (
                                                    <option key={state.id_no} value={state.id_no}>
                                                        {state.state}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Local government</Form.Label>
                                            <Form.Control as="select" value={selectedLocalGovt} onChange={(e) => setSelectedLocalGovt(e.target.value)}>
                                                <option value="">Select local government</option>
                                                {localGovernments.map((localGovt) => (
                                                    <option key={localGovt.id_no} value={localGovt.id_no}>
                                                        {localGovt.local_govt}
                                                    </option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>


                                </Row>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                {/* <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button> */}
                                <Button id='edit' variant="success" onClick={handleSave} >{loading ? <ActivityIndicator /> : 'Save Changes'}</Button>

                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
            <div className='body'>
                <div className='main'>

                    <Tabs
                        defaultActiveKey="home"

                        id="uncontrolled-tab-example"
                        className="mb-3">
                        <Tab eventKey="home" id='nav-link' title="My Orders" className="profile-tabs" >
                            <section className='tabletop-info'>
                                <div className='sp-table'>
                                    {renderTable()}
                                </div>
                            </section>
                        </Tab>

                        <Tab eventKey="profile" title="Address Book" style={{}}>

                            <section className='first-address'>
                                <div className='add-content'>
                                    <div className='header-address'>
                                        <p className='uno'>Default Address</p>

                                        <div className='the-edit'>
                                            <img src={Editfld} alt='User' style={{ width: '20px', height: '20px' }} className='rectangle' />
                                            <p onClick={handleAddressShow} style={{ marginLeft: '5px', fontSize: '14px', fontWeight: '600', color: '#FBBC05', cursor: "pointer" }}>Edit</p>
                                        </div>
                                    </div>
                                    <div className=''>

                                        <div className='the-add-info'>
                                            <img src={Lomap} alt='User' style={{ width: '20px', height: '20px', marginRight: '8px', marginBottom: '-5px' }} className='rectangle' />
                                            <p style={{ fontSize: '14px', fontWeight: '400' }}>
                                                {address ? address : "No address, tap on 'Edit' to enter your address"}
                                            </p>
                                        </div>

                                    </div>


                                </div>
                                <Modal show={addressShow} onHide={handleAddressClose}>
                                    <Modal.Header closeButton>

                                        <Image src={avatar} style={{ height: "50px", width: "50px" }} rounded />
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form><Row>
                                            <Col xs={12} md={12}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control
                                                    type="address"
                                                    placeholder="Enter Address"
                                                    autoFocus
                                                    value={address}
                                                    onChange={(e) => setAddress(e.target.value)}
                                                />
                                            </Form.Group></Col>
                                        </Row></Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="success" onClick={handleNewAddress} >{loading ? <ActivityIndicator /> : 'Save Changes'}</Button>

                                    </Modal.Footer>
                                </Modal>

                            </section>
                            <section className='first-address'>
                                <div className='add-content'>
                                    <div className='header-address'>
                                        <p className='uno'>Other Address 1</p>

                                        <div className='the-edit'>
                                            <img src={Editfld} alt='User' style={{ width: '20px', height: '20px' }} className='rectangle' />
                                            <p onClick={handleAddress1Show} style={{ marginTop: '-5px', marginLeft: '5px', fontSize: '14px', fontWeight: '600', color: '#FBBC05', cursor: "pointer" }}>Edit</p>
                                        </div>
                                    </div>
                                    <div className=''>

                                        <div className='the-add-info'>
                                            <img src={Lomap} alt='User' style={{ width: '20px', height: '20px', marginRight: '8px', marginBottom: '-5px' }} className='rectangle' />
                                            <p style={{ fontSize: '14px', fontWeight: '400', marginTop: '-4px' }}>{address1 ? address1 : "No address, tap on 'Edit' to enter your address"} </p>
                                        </div>

                                        <Button id='address1-save' variant="success" onClick={handleSetasDefault1} >{loadingg ? 'Setting as default...' : 'Set as default'}</Button>
                                    </div>


                                </div>
                                <Modal show={address1Show} onHide={handleAddress1Close}>
                                    <Modal.Header closeButton>

                                        <Image src={avatar} style={{ height: "50px", width: "50px" }} rounded />
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form><Row>
                                            <Col xs={12} md={12}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control
                                                    type="address"
                                                    placeholder="Enter Address"
                                                    autoFocus
                                                    value={address1}
                                                    onChange={(e) => setAddress1(e.target.value)}
                                                />
                                            </Form.Group></Col>
                                        </Row></Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="success" onClick={handleNewAddress1} >{loading ? <ActivityIndicator /> : 'Save Changes'}</Button>

                                    </Modal.Footer>
                                </Modal>

                            </section>

                            <section className='first-address'>
                                <div className='add-content'>
                                    <div className='header-address'>
                                        <p className='uno'>Other Address 2</p>

                                        <div className='the-edit'>
                                            <img src={Editfld} alt='User' style={{ width: '20px', height: '20px' }} className='rectangle' />
                                            <p onClick={handleAddress2Show} style={{ marginTop: '-5px', marginLeft: '5px', fontSize: '14px', fontWeight: '600', color: '#FBBC05', cursor: "pointer" }}>Edit</p>
                                        </div>
                                    </div>
                                    <div className=''>

                                        <div className='the-add-info'>
                                            <img src={Lomap} alt='User' style={{ width: '20px', height: '20px', marginRight: '8px', marginBottom: '-5px' }} className='rectangle' />
                                            <p style={{ fontSize: '14px', fontWeight: '400', marginTop: '-4px' }}>{address2 ? address2 : "No address, tap on 'Edit' to enter your address"} </p>
                                        </div>

                                        <Button id='address2-save' className='save-address' variant="success" onClick={handleSetasDefault2} >{load ? 'Setting as default...' : 'Set as default'}</Button>
                                    </div>


                                </div>
                                <Modal show={address2Show} onHide={handleAddress2Close}>
                                    <Modal.Header closeButton>

                                        <Image src={avatar} style={{ height: "50px", width: "50px" }} rounded />
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form><Row>
                                            <Col xs={12} md={12}> <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Address</Form.Label>
                                                <Form.Control
                                                    type="address"
                                                    placeholder="Enter Address"
                                                    autoFocus
                                                    value={address2}
                                                    onChange={(e) => setAddress2(e.target.value)}
                                                />
                                            </Form.Group></Col>
                                        </Row></Form>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="success" onClick={handleNewAddress2} >{loading ? <ActivityIndicator /> : 'Save Changes'}</Button>

                                    </Modal.Footer>
                                </Modal>

                            </section>





                        </Tab>
                        <Tab eventKey="contact" title="Wishlist" >
                            <section className='tabletop-info'>
                                {/* <div style={{ display: 'flex', justifyContent: 'center', }}>
                                    <img src={Heartfave} className='heartyheart' alt='favorite' style={{ width: '20px', height: '20px', marginTop: '5px', marginRight: '10px' }} />
                                </div> */}
                                <div>
                                    {renderWishlist()}
                                </div>
                            </section>
                        </Tab>
                    </Tabs>

                    {/* </div> */}
                    {/* </div> */}
                </div>
            </div>

            <Newfooter />
        </div>


    )
}