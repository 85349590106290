import React from 'react'
import fruit1 from '../Images/fruit1.png';
import fruit2 from '../Images/fruit2.png';
import fruit3 from '../Images/fruit3.png';
import Footer from '../Components/Footer';
import NavbarHomepage from '../Components/NavbarHomepage';



export const Vegitables = () => {
  return (
    <div>
        <NavbarHomepage/>
    <div className='body'>
        <div className='main'>
            <div className='Catig'>
            
                <div className='fruits'>
                    <h1>Vegetables</h1>
                    <p>Showing 1- 15 of 200 results</p>
                    <div className='fruit-grid'>
                    <div className='img1'>
                        <img src={fruit1} alt='fruits'/>
                        <div>
                        <h4>Vegitable</h4>
                        </div>
                        <h4><small>$</small>95<small>.00</small></h4>
                        <p>Red Silettos</p>
                        <span className='star-details'>
                        <div id='solid-stars'>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                        </div>
                        (91)
                        </span>
                        <button className='addToCart'><i className='bx bx-cart'></i> Add to Cart</button>
                    </div>
                    <div className='img1'>
                        <img src={fruit2} alt='fruits'/>
                        <div>
                        <h4>Court Heels</h4>
                        </div>
                        <h4><small>$</small>95<small>.00</small></h4>
                        <p>Red Silettos</p>
                        <span className='star-details'>
                        <div id='solid-stars'>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                        </div>
                        (91)
                        </span>
                        <button className='addToCart'><i className='bx bx-cart'></i> Add to Cart</button>
                    </div>
                    <div className='img1'>
                        <img src={fruit3} alt='fruits'/>
                        <div>
                        <h4>Court Heels</h4>
                        </div>
                        <h4><small>$</small>95<small>.00</small></h4>
                        <p>Red Silettos</p>
                        <span className='star-details'>
                        <div id='solid-stars'>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                        </div>
                        (91)
                        </span>
                        <button className='addToCart'><i className='bx bx-cart'></i> Add to Cart</button>
                    </div>
                    <div className='img1'>
                        <img src={fruit1} alt='fruits'/>
                        <div>
                        <h4>Vegitable</h4>
                        </div>
                        <h4><small>$</small>95<small>.00</small></h4>
                        <p>Red Silettos</p>
                        <span className='star-details'>
                        <div id='solid-stars'>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                        </div>
                        (91)
                        </span>
                        <button className='addToCart'><i className='bx bx-cart'></i> Add to Cart</button>
                    </div>
                    <div className='img1'>
                        <img src={fruit2} alt='fruits'/>
                        <div>
                        <h4>Court Heels</h4>
                        </div>
                        <h4><small>$</small>95<small>.00</small></h4>
                        <p>Red Silettos</p>
                        <span className='star-details'>
                        <div id='solid-stars'>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                        </div>
                        (91)
                        </span>
                        <button className='addToCart'><i className='bx bx-cart'></i> Add to Cart</button>
                    </div>
                    <div className='img1'>
                        <img src={fruit3} alt='fruits'/>
                        <div>
                        <h4>Court Heels</h4>
                        </div>
                        <h4><small>$</small>95<small>.00</small></h4>
                        <p>Red Silettos</p>
                        <span className='star-details'>
                        <div id='solid-stars'>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                        </div>
                        (91)
                        </span>
                        <button className='addToCart'><i className='bx bx-cart'></i> Add to Cart</button>
                    </div>
                    <div className='img1'>
                        <img src={fruit1} alt='fruits'/>
                        <div>
                        <h4>Vegitable</h4>
                        </div>
                        <h4><small>$</small>95<small>.00</small></h4>
                        <p>Red Silettos</p>
                        <span className='star-details'>
                        <div id='solid-stars'>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                        </div>
                        (91)
                        </span>
                        <button className='addToCart'><i className='bx bx-cart'></i> Add to Cart</button>
                    </div>
                    <div className='img1'>
                        <img src={fruit2} alt='fruits'/>
                        <div>
                        <h4>Court Heels</h4>
                        </div>
                        <h4><small>$</small>95<small>.00</small></h4>
                        <p>Red Silettos</p>
                        <span className='star-details'>
                        <div id='solid-stars'>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                        </div>
                        (91)
                        </span>
                        <button className='addToCart'><i className='bx bx-cart'></i> Add to Cart</button>
                    </div>
                    <div className='img1'>
                        <img src={fruit3} alt='fruits'/>
                        <div>
                        <h4>Court Heels</h4>
                        </div>
                        <h4><small>$</small>95<small>.00</small></h4>
                        <p>Red Silettos</p>
                        <span className='star-details'>
                        <div id='solid-stars'>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                            <i className='bx bxs-star'></i>
                        </div>
                        (91)
                        </span>
                        <button className='addToCart'><i className='bx bx-cart'></i> Add to Cart</button>
                    </div>
                    </div>
                    <div className='pagnations'>

                    </div>
                </div>


            </div>
            
        </div>
    </div>
    <Footer/>
    </div>
  )
}
