import React, { useState, useEffect } from 'react'
import SummaryRice from '../Images/SummaryRice.png';
import { Mininavthree } from '../Components/Mininavthree';
import { OrderSummaryProducts } from '../Components/OrderSummaryProducts';
import { Deliveryinfomation } from '../Components/Deliveryinfomation';
import { Paymentinformation } from '../Components/Paymentinformation';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import NavbarHomepage from '../Components/NavbarHomepage';
import Swal from 'sweetalert2';
import Success from '../Images/success1.jpg';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ActivityIndicator from '../Components/ActivityIndicator';
import Footer from '../Components/Footer';

const CheckoutPage = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [cartItems, setCartItems] = useState([]);
  const [customer, setCustomer] = useState([]);
  const [amount, setAmount] = useState([]);
  const [product, setProduct] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState([])
  const [bearer, setBearer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState('');
  const [deliveryMethod, setDeliveryMethod] = useState('');
  const [itemQuantities, setItemQuantities] = useState([]);
  const [memberDetails, setMemberDetails] = useState([]);
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [user, setUser] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [firstname, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [deliveryOptions, setDeliveryOptions] = useState([]);
  const [selectedStoreText, setSelectedStoreText] = useState('');
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [selectedStoreId, setSelectedStoreId] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const navigate = useNavigate();

  const handleOptionChange = (event) => {
    setSelectedPayment(event.target.value);
  };

  const handleDelivery = (event) => {
    setDeliveryMethod(event.target.value);
  };

  const readData = async () => {
    try {
      const value2 = await AsyncStorage.getItem('userToken');

      if (value2 !== null) {
        // console.log(value2);
        setBearer(value2);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };
  useEffect(() => {
    readData();
  }, []);

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const calculateTotalPrice = () => {
    let total = 0;
    cartItems.forEach((item, index) => {
      total += item.product.price * itemQuantities[index];
    });
    const formattedTotal = total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    // setTotal(formattedTotal)
    return formattedTotal;
  };

  const fetchAllOrders = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(`https://gtc.ogunstate.gov.ng/api/customer-cart`,
        {
          headers,
        }
      );
      console.log(cartItems)
      const result = response?.data?.data;
      setCartItems(result);
      //   const prodIds = result.product.id;
      //   setProdID(prodIds);
      const initialQuantities = result.map(item => Number(item.quantity));
      setItemQuantities(initialQuantities);
      const uniqueCustomers = Array.from(new Set(result.map(item => item.customer_id)));
      if (result.length === 0) {

        setIsLoading(false);
      } else if (uniqueCustomers.length === 1) {
        const customerId = uniqueCustomers[0];
        setCustomer(customerId);
      } else {
        console.error('Error: Customer IDs are not the same');
        setIsLoading(false);
      }
      const delivery = Array.from(new Set(result.map(item => item.delivery_fee)));
      if (result.length === 0) {

        setIsLoading(false);
      } else if (delivery.length === 1) {
        const deliverFee = delivery[0];
        setDeliveryFee(deliverFee);
      } else {
        console.error('Error: Delivery fee are not the same');
        setIsLoading(false);
      }
      const uniqueProductIds = result.map(item => item.product_id);
      setProduct(uniqueProductIds);
      const amounts = result.map(item => item.amount);
      setAmount(amounts);
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      setCartItems([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (bearer) {
      fetchAllOrders();
    }
  }, [bearer]);

  const fetchMemberDetails = async () => {
    try {
      const response = await axios.get(`https://gtc.ogunstate.gov.ng/api/profile`, { headers });
      const details = response?.data?.data;
      const userDetail = details.firstname + " " + details.lastname;
      setMemberDetails(details);
      setFirstName(details.firstname)
      setLastName(details.lastname)
      setPhone(details.phone_number)
      setAddress(details.address)
      const addresses = [details.address, details.address_1, details.address_2];
      setAddresses(addresses);
      setEmail(details.email)
      setUser(userDetail)


      // console.log(memberDetails)

      // console.log(email)

    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false); // Set isLoading to false after fetching member details, regardless of success or error
    }
  };

  useEffect(() => {
    readData();
    if (bearer) {
      setIsLoading(true);
      fetchMemberDetails();


    }
  }, [bearer]);


  const handleAddressSelect = (address) => {
    setSelectedAddress(address);
  };

  const handleStoreSelect = (store) => {
    setSelectedStoreText(store.store_address);
    setSelectedStoreId(store.store_id);
  };


  const fetchAllStores = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`https://gtc.ogunstate.gov.ng/api/stores`, {
        headers,
      });
      const allStores = response?.data?.data;
      if (Array.isArray(allStores)) {
        const outletStores = allStores.filter(store => store.type === "Outlet");
        setStores(outletStores);

        if (outletStores.length > 0) {
          setSelectedStore(outletStores[0]);
          setSelectedStoreText(outletStores[0].store_address);
          setSelectedStoreId(outletStores[0].store_id);
        }
      } else {
        setStores([]);
      }
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      setStores([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (bearer) {
      fetchAllStores();
    }
  }, [bearer]);

  const maxLength = 40;

  // console.log(address)

  const handleCheckout = async () => {
    setLoad(true);
    try {
      let deliveryDetail = '';

      if (deliveryMethod === 'Door Delivery') {
        deliveryDetail = selectedAddress;
      } else if (deliveryMethod === 'Pickup Station') {
        deliveryDetail = selectedStoreId;
      }

      const itemQuantitiesAsString = itemQuantities.map(quantity => quantity.toString());

      const requestBody = {
        product_id: product,
        customer_id: customer,
        quantity: itemQuantitiesAsString,
        amount: amount,
        mode_of_delivery: deliveryMethod
      };

      if (deliveryMethod === 'Door Delivery') {
        requestBody.delivery_address = deliveryDetail;
      } else if (deliveryMethod === 'Pickup Station') {
        requestBody.store_id = deliveryDetail;
      }

      const response = await axios.post(
        'https://gtc.ogunstate.gov.ng/api/make-order',
        requestBody,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearer}`
          }
        }
      );

      console.log('Success:', response.data.message);
      fetchAllOrders();
      Swal.fire({
        imageUrl: Success,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Successful',
        title: 'Order Placed Successfully!',
        text: 'Your order has been placed successfully. Thank you for shopping with us.',
        showConfirmButton: true,
        confirmButtonText: 'Back Home',
        confirmButtonColor: "#2F945C",
      }).then(() => {
        navigate('/HomePage');
      });
    } catch (error) {
      // Handle error response
      const errorMessage = error.response?.data?.message || 'An error occurred';
      console.log('Error:', errorMessage);
      
      Swal.fire({
       icon: "error",
        title: 'Oooops.....',
        text: errorMessage, // Display the error message from the API response
        icon: 'error', // You can use other icons like 'warning', 'info', etc.
        showConfirmButton: true,
        confirmButtonText: 'OK',
        confirmButtonColor: '#FF5733', // Customize the button color
      });
      
    } finally {
      setLoad(false);
    }
  }




  console.log(selectedStoreId, selectedAddress)

  return (
    <div className='main'>
      <div id='CheckoutMininavthree'>
        {/* <Mininavthree /> */}
      </div>
      <div className='Checkout'>
        <div>
          <div className='orderSummary'>
            <div className='my-cart'>
              <span>
                <h1>Order Summary</h1>
                <input type='text' value={cartItems.length} style={{ color: "#fff" }} />
              </span>
            </div>
            <div className='ProductInfo'>
              {cartItems.map((cartItem, index) => (
                <div className='cart-item' key={index}>
                  <div className='CartDetails'>
                    <img src={cartItem.product.image} alt={cartItem.product.name} style={{ height: "120px", width: "120px" }} />
                    <div>
                      <h4>{cartItem.product.name}</h4>
                      <p>Qty: {itemQuantities[index]}</p>
                      <div className='price'>
                        <h4 style={{color:'#2F945C',}}>₦{Number(cartItem.product.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

          </div>
            {/* <div className='deliveryInfo'>
              {selectedPayment && (
                  <div>
                    <h2>Payment Method</h2>
                    <p>{selectedPayment}</p>
                  </div>
                )}

    

              {deliveryMethod && (
                <div className='content' style={{marginTop: 50}}>
                <h2>Delivery Method</h2>
                  <h4>{deliveryMethod}</h4>
                  <span>
                    <i className='bx bx-map-alt'></i>
                    <p>{deliveryMethod === 'Door Delivery' ? selectedAddress || address : deliveryMethod === 'Pickup Station' ? selectedStoreText : ''}</p>
                  </span>
                  <span>
                    <i className='bx bx-phone'></i>
                    <p>{phone}</p>
                  </span>
                  <span>
                    <i className='bx bx-envelope'></i>
                    <p>{email}</p>
                  </span>
                </div>
                )}
              </div> */}
        </div>
        <div className='PaymentInfo'>
          <h3>Payment Information</h3>
          <h4>Apply Discount</h4>
          <div className='inputs'>
            <input type='text' placeholder='Enter Coupon Code' />
            <button>Apply</button>
          </div>
          <div className='payment-options'>
            <h4>Select Payment Method</h4>
            <div className='payment-option'>
              <input
                type='radio'
                id='Ogun State BPMS'
                name='paymentMethod'
                value='Ogun State BPMS'
                checked={selectedPayment === 'Ogun State BPMS'}
                onChange={handleOptionChange}
              />
              <label htmlFor='Ogun State BPMS'>Ogun State BPMS</label>
            </div>
            <div className='payment-option'>
              <input
                type='radio'
                id='Pay Later'
                name='paymentMethod'
                value='Pay Later'
                checked={selectedPayment === 'Pay Later'}
                onChange={handleOptionChange}
              />
              <label htmlFor='Pay Later'>Pay Later</label>
            </div>
            {/* <p>You selected: {selectedPayment}</p> */}
          </div>


          <div className='payment-options'>
            <h4>Select Delivery Method</h4>
            <div className='payment-option'>
              <input
                type='radio'
                id='Door Delivery'
                name='deliveryMethod'
                value='Door Delivery'
                checked={deliveryMethod === 'Door Delivery'}
                onChange={handleDelivery}
              />
              <label htmlFor='Door Delivery'>Door Delivery</label>
            </div>

            {deliveryMethod === 'Door Delivery' && (
              <DropdownButton id="dropdown-item-button" title={selectedAddress.length > maxLength ? `${selectedAddress.substring(0, maxLength)}...` : selectedAddress || 'Select your Address'}>
                {loading ? (
                  <Dropdown.ItemText>Loading...</Dropdown.ItemText>
                ) : (
                  addresses
                    .filter((address) => address)
                    .map((address, index) => (
                      <Dropdown.Item
                        as="button"
                        key={index}
                        onClick={() => handleAddressSelect(address)}
                      >
                        {address.length > maxLength ? `${address.substring(0, maxLength)}...` : address}
                      </Dropdown.Item>
                    ))
                )}
              </DropdownButton>

            )}




            <div className='payment-option'>
              <input
                type='radio'
                id='Pickup Station'
                name='deliveryMethod'
                value='Pickup Station'
                checked={deliveryMethod === 'Pickup Station'}
                onChange={handleDelivery}
              />
              <label htmlFor='Pickup Station'>Pickup Station</label>
            </div>
            {deliveryMethod === 'Pickup Station' && (
              <DropdownButton id="dropdown-item-button" title={selectedStoreText.length > maxLength ? `${selectedStoreText.substring(0, maxLength)}...` : selectedStoreText || 'Select your desired Store'}>
                {loading ? (
                  <Dropdown.ItemText>Loading...</Dropdown.ItemText>
                ) : (
                  stores
                    .filter((store) => store)
                    .map((store, index) => (
                      <Dropdown.Item
                        as="button"
                        key={index}
                        onClick={() => handleStoreSelect(store)}
                      >
                        {store.store_address.length > maxLength ? `${store.store_address.substring(0, maxLength)}...` : store.store_address}
                      </Dropdown.Item>
                    ))
                )}
              </DropdownButton>

            )}






          </div>

          <div className='paymentDetails'>
            <div>
              <p>Sub Total</p>
              <p>₦{calculateTotalPrice()}</p>
            </div>
            <div>
              <p>Delivery Fee </p>
              <p>₦{Number(deliveryFee).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
            </div>
            {/* <div>
                <p>Shipping</p>
                <p>$0.00</p>
              </div> */}
          </div>
          <div className='total'>
            <p>Total</p>
            <span>₦{calculateTotalPrice()}</span>
          </div>
          <button onClick={handleCheckout} id="pay">
            {load ? <ActivityIndicator /> : `Proceed to pay ₦${calculateTotalPrice()}`}
          </button>
        </div>
      </div>
      {/* {errorMessage ? (
            <div className="error-message">
              {errorMessage}
            </div>
          ) : null} */}
    </div>
  )
}
export default CheckoutPage