import React, { createContext, useContext, useReducer, useEffect, useState } from 'react';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_CART':
      return action.payload;
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  const [cartItems, dispatch] = useReducer(cartReducer, []);
  const [bearer, setBearer] = useState('');

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      // console.log('heere');
      if (value !== null) {
        // console.log("Cart Context Here", value)
        setBearer(value);
      
      } else {
        setBearer(''); 
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  
  useEffect(() => {
    // readData();
    readData()

    .then((data) => {
      // Handle the retrieved data
      fetchAllOrders(); // Fetch cart items when the user is signed in
    })
    .catch((error) => {
      // Handle any errors
    });

    
  }, []);


  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  const fetchAllOrders = async () => {
    try {
      readData()
      if (!bearer) {
        return; // If the bearer is empty, do not make the request
      }
      const response = await axios.get('https://gtc.ogunstate.gov.ng/api/customer-cart', {
        headers,
      });

      const fetchedItems = response?.data?.data;
      // console.log(fetchedItems, 'here')
      dispatch({ type: 'UPDATE_CART', payload: fetchedItems });
    } catch (error) {
      const errorStatus = error.response?.data?.message;
      dispatch({ type: 'UPDATE_CART', payload: [] });
    }
  };

  
  useEffect(() => {
    const fetchInterval = 2000; // 2 seconds

    const startFetching = () => {
      fetchAllOrders(); // Fetch cart items at the specified interval
    };

    // Start fetching immediately
    startFetching();

    // Set up a timer to fetch data at the specified interval
    const intervalId = setInterval(startFetching, fetchInterval);

    // Clean up the timer when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, [bearer]);

  return (
    <CartContext.Provider value={cartItems}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
