import React from 'react'
import NavbarHomepage from '../Components/NavbarHomepage';
import Footer from '../Components/Footer';
import Heartfave from '../Emojis/favorite.png';
import Wishproduct from '../Images/wishprodt.png';
import Trashcan from '../Images/trashcan.png';
import Horline from '../Images/hrline.png';



export const Wishlist = () => {
    return (
      <div style={{   background: '#FBFEFC',   }}>
<NavbarHomepage/>
<div className='fave-wishlist'>
<img src={Heartfave} className='heartyheart' alt='favorite'/>
<p className='wish'>Wishlist</p>

</div>
<img src={Horline} className='horline' alt='favorite'/>

<div className='pgish'>
    <p>Product Name</p>
    <p>Unit Price</p>
    <p>Stock Status</p>
    <p>Action</p>
    
</div>
<img src={Horline} className='horline' alt='favorite'/>

<div className='pgish1'>
<img src={Trashcan} className='trashcan' alt='trash'/> 
<img src={Wishproduct} className='wishprodt' alt='wishprod'/> 
    <p>Product Name</p>
    <p>Unit Price</p>
    <p>Stock Status</p>
    {/* <p>Action</p> */}
    <button style={{ fontSize: '14px', color: 'white', fontweight: '600', background: 'var(--green)', height: "48px", width: "113px", border: '1px solid var(--green)' }}>Add To Cart</button>
</div>
<img src={Horline} className='horline' alt='favorite'/>

<div className='pgish1'>
<img src={Trashcan} className='trashcan' alt='trash'/> 
<img src={Wishproduct} className='wishprodt' alt='wishprod'/> 
    <p>Product Name</p>
    <p>Unit Price</p>
    <p>Stock Status</p>
    {/* <p>Action</p> */}
    <button style={{ fontSize: '14px', color: 'white', fontweight: '600', background: 'var(--green)', height: "48px", width: "113px", border: '1px solid var(--green)' }}>Add To Cart</button>
</div>
<img src={Horline} className='horline' alt='favorite'/>

<div className='pgish1'>
<img src={Trashcan} className='trashcan' alt='trash'/> 
<img src={Wishproduct} className='wishprodt' alt='wishprod'/> 
    <p>Product Name</p>
    <p>Unit Price</p>
    <p>Stock Status</p>
    {/* <p>Action</p> */}
    <button style={{ fontSize: '14px', color: 'white', fontweight: '600', background: 'var(--green)', height: "48px", width: "113px", border: '1px solid var(--green)' }}>Add To Cart</button>
</div>
<img src={Horline} className='horline' alt='favorite'/>





<Footer/>
    </div>
  )
}
