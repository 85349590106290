import React, { useEffect, useState } from 'react';
import Logo from "../Images/GTCAPP.png";
import { NavLink, Link, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

const Newnav = () => {
  const navigate = useNavigate();
  const [bearer, setBearer] = useState('');
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state to control menu open/close

  const readData = async () => {
    try {
      const value = await AsyncStorage.getItem('userToken');
      if (value !== null) {
        setBearer(value);
      }
    } catch (e) {
      alert('Failed to fetch the input from storage');
    }
  };

  useEffect(() => {
    readData();
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu open/close state
  };

  const closeMenu = () => {
    setIsMenuOpen(false); // Close the menu
  };

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${bearer}`
  };

  return (
    <div className="header-section" >
      <div className="overlay">
        <div className="row d-flex header-area">
          <nav className="navbar navbar-expand-lg navbar-light sticky-top">
            <div id="nav-container">
              <NavLink to={'/'}><img src={Logo} alt='Logo' className='nwwbp' /></NavLink>

              <button
                className="navbar-toggler"
                type="button"
                onClick={handleMenuToggle} // Toggle menu on button click
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="fas fa-bars"></i>
              </button>
              <div className={`collapse navbar-collapse${isMenuOpen ? ' show' : ''}`} id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <NavLink to={'/'} className="nav-link" onClick={closeMenu}>Home</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/categories'} className="nav-link" onClick={closeMenu}>Categories</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/about_us'} className="nav-link" onClick={closeMenu}>About Us</NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to={'/contact_us'} className="nav-link" onClick={closeMenu}>Contact Us</NavLink>
                  </li>
                  <div className="header-ordernow">
                    <button type="button"  onClick={closeMenu}>
                      <NavLink to={"/categories"}>Order Now</NavLink>
                    </button>
                    <button type="button" className="btn Order-Now" onClick={closeMenu}>
                      <NavLink to={"/sign_in"}>Login</NavLink>
                    </button>
                    
                  </div>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Newnav;
