import React from 'react';
import OnbImg from '../Images/pwd-crte-img.png'
import { GoBackHome } from '../Components/GoBackHome';

export const AddressBook = () => {
  return (
    <div className='signin background'>
        <div className='marketersImg'>
            <img src={OnbImg} className="leftonb-img" alt="img" />
        </div>
        
        <div className='general-con'>
            <div className='go-back'>
                <GoBackHome/>
            </div>
        <div className='Addressbook'>
            <div className='container'>
                <div style={{display:'flex', justifyContent:'space-between',}}>
                    <div></div>
                    <button className='edit'>EDIT <i class='bx bxs-pencil'></i></button>
                </div>
                <div id='location-detail'>
                    <input type='radio' id='radio' style={{width:'20px', BackgroundColor:'#2F945C',}}/>
                    <p>
                        Oke Ilewo, Key Plaza, ABEOKUTA-Central-Ogun <br/>
                    </p>  
                </div>
            </div>
        </div>
        <div className='Addressbook'>
            <div className='container'>
                <div style={{display:'flex', justifyContent:'space-between',}}>
                    <div></div>
                    <button className='edit'>EDIT <i class='bx bxs-pencil'></i></button>
                </div>
                <div id='location-detail'>
                    <input type='radio' id='radio' style={{width:'20px', BackgroundColor:'#2F945C',}}/>
                    <p>
                        Oke Ilewo, Key Plaza, ABEOKUTA-Central-Ogun <br/>
                    </p>  
                </div>
            </div>
        </div>
        <div className='Addressbook'>
            <div className='container'>
                <div style={{display:'flex', justifyContent:'space-between',}}>
                    <div></div>
                    <button className='edit'>EDIT <i class='bx bxs-pencil'></i></button>
                </div>
                <div id='location-detail'>
                    <input type='radio' id='radio' style={{width:'20px', BackgroundColor:'#2F945C',}}/>
                    <p>
                        Oke Ilewo, Key Plaza, ABEOKUTA-Central-Ogun <br/>
                    </p>  
                </div>
            </div>
        </div>
        </div>
    </div>
  )
}